import React from 'react';
import { Resource } from 'react-admin';

import {Client} from '../containers/clients/index';
import {ClientDefibrillateurs} from '../containers/defibrillateurs/index';
import {InterventionsClosed, AddIntervention} from '../containers/interventions/index';
import {Rapport} from '../containers/rapports/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faHeartbeat, faUserTie, faToolbox } from '@fortawesome/free-solid-svg-icons'


const ClientResources = [
    <Resource 
        options={{expose: true, key : 'defibrillateurs', label: 'Mes défibrillateurs', icon: <FontAwesomeIcon icon={faHeartbeat} />}} 
        name="defibrillateurs" 
        {...ClientDefibrillateurs}
    />,
    <Resource 
        options={{expose : false, key : 'client_self', icon: <FontAwesomeIcon icon={faUserTie} />}} 
        name="clients" 
        {...Client}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_new', type: 'new', label: 'En attente', icon: <FontAwesomeIcon icon={faToolbox}/>}} 
        name="interventions/new" 
        {...InterventionsClosed}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_processing', type: 'processing', label: 'En cours', icon: <FontAwesomeIcon icon={faToolbox}/>}} 
        name="interventions/processing" 
        {...InterventionsClosed}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_processed', type: 'processed', label: 'Traitées', icon: <FontAwesomeIcon icon={faToolbox}/>}} 
        name="interventions/processed" 
        {...InterventionsClosed}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_closed', type: 'closed', label: 'Fermées', icon: <FontAwesomeIcon icon={faToolbox} />}} 
        name="interventions/closed"
        {...InterventionsClosed}
    />,
        <Resource key="res_interventions_add"
        options={{menu : 'interventions', expose : false, key : 'interventions_add', label: 'Demander une intervention', icon: <FontAwesomeIcon icon={faToolbox} />}} 
        name="interventions" 
        {...AddIntervention} 
    />,
    <Resource 
        options={{menu: 'rapports', type: 'maintenance', expose : true, key : 'rapports_maint', label: 'Maintenance', 
            icon: <FontAwesomeIcon icon={faClipboardList} />
        }} 
        name="rapports/maintenance" 
        {...Rapport}
    />,
    <Resource 
        options={{menu: 'rapports', type: 'installation', expose : true, key : 'rapports_install', label: 'Installation', 
            icon: <FontAwesomeIcon icon={faClipboardList} />
        }} 
        name="rapports/installation" 
        {...Rapport}
    />,
];

export default ClientResources;