import React, {useEffect, useState } from 'react';
import { 
    Create,
    CreateButton,
    SimpleForm,
    AutocompleteInput,
    RadioButtonGroupInput,
    TextInput,
    useDataProvider
} from 'react-admin';
import {validateUsers} from './UserValidator';
import Alert from '@material-ui/lab/Alert';


const UserCreate = (props) => {
    const type = props.options.type;
    const role = ["ROLE_"+type.toUpperCase()];
    const dataProvider = useDataProvider();

    const [clients, setClients] = useState([]);
    const [societes, setSocietes] = useState([]);
    const [clientsLoaded, setClientsLoaded] = useState(false);
    const [societesLoaded, setSocietesLoaded] = useState(false);

    let clientsDependancy = ['client', 'referent'];
    let hasClientDependancy = clientsDependancy.includes(type) ? true : false;
    let societesPermissions = ['societe', 'technicien'];
    let hasSocietesPermissions = societesPermissions.includes(type) ? true : false;


    useEffect(function fetchDefibs() {
        let isMounted = true;
        if(hasClientDependancy){
            dataProvider.getList('clients', {
                filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }, output: 'short'
            }).then((response) => {
                if (isMounted) {
                    setClients(response.data);
                    setClientsLoaded(true);
                }
            });
        }
        if(hasSocietesPermissions){
            dataProvider.getList('societes', {
                filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }, group: false
            }).then((response) => {
                if (isMounted) {
                    setSocietes(response.data);
                    setSocietesLoaded(true);
                }
            });
        }
        return () => { isMounted = false };
    }, [clientsLoaded, societesLoaded, dataProvider, hasClientDependancy, hasSocietesPermissions]);

    var choicesClients = [];
    clients.map(client => (
        choicesClients.push({clientId: client.id, name: client.nom, id:"cli_"+client.id})
    ));
    
    var choicesSocietes = [];
    societes.map(societe => (
        choicesSocietes.push({societeId: societe.id, name: societe.nom, id:"soc_"+societe.id})
    ));

    let noClient = clientsLoaded && hasClientDependancy && clients.length < 1 ? true : false;
    let noSociete = societesLoaded && type === 'societe' && societes.length < 1 ? true : false;

    const displayAlert = noClient || noSociete ? true : false;

    let alert = '';
    if(noSociete){
        alert = <Alert severity="warning">Vous devez ajouter au moins une société pour pouvoir continuer <CreateButton variant="contained" basePath="/societes" /></Alert>;
    } else if(noClient){
        alert =  <Alert severity="warning">Vous devez ajouter au moins un client pour pouvoir continuer <CreateButton variant="contained" basePath="/clients" /></Alert>;
    }
    


    return (
        clientsLoaded || societesLoaded ? (
            displayAlert ? 
                alert
            :
            <Create {...props}>
                <SimpleForm validate={validateUsers}>
                    <TextInput source="email" type="email"/>
                    {type === 'client' || type === 'referent' ? ([
                        <AutocompleteInput source="clientId" label="Client" optionValue="clientId" choices={choicesClients}/>,       
                    ]) : null}
                    {(type === 'societe' || type === 'technicien') && !noSociete ? (
                        <AutocompleteInput source="societeId" label="Societe" optionValue="societeId" choices={choicesSocietes}/>
                    ) : null}
                    <RadioButtonGroupInput source="civilite" choices={[
                        {id: "0", name: 'Monsieur'},
                        {id: "1", name: 'Madame'},
                    ]}/>
                    <TextInput source="roles" label="Rôle" defaultValue={role} disabled/>
                    {type === 'referent' ? (
                        <TextInput source="fonction" label="Fonction"/>
                    ) : null }

                    <TextInput source="nom" />
                    <TextInput source="prenom" label="Prénom"/>
                    {type === 'referent' ? ([
                        <TextInput source="adresse1" label="Adresse 1"/>,
                        <TextInput source="adresse2" label="Adresse 2"/>,
                        <TextInput source="adresse3" label="Complément d'adresse"/>,
                        <TextInput source="cp" label="Code Postal"/>,
                        <TextInput source="ville" label="Ville"/>,
                        <TextInput source="pays" label="Pays"/>

                    ]) : null}
                    <TextInput source="tel" label="Téléphone"/>
                </SimpleForm>
            </Create>
        ) : null
    )
};


export default UserCreate;