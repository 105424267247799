import React, { useEffect, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { 
    Create,
    TabbedForm, 
    FormTab, 
    FormDataConsumer,
    BooleanInput,
    RadioButtonGroupInput,
    DateInput, 
    TextInput, 
    SelectInput,
    useDataProvider
} from 'react-admin';
import { useForm } from 'react-final-form';
import Alert from '@material-ui/lab/Alert';
import {validateCreateRapport} from './RapportValidator';

const Signature = () => {
    const form = useForm();
    let sigCanvas = {};

    const clear = () => {        
        sigCanvas.clear();
    };

    const save = () => {
        form.change('signature', sigCanvas.getCanvas().toDataURL());
    }

    return (
        <>
            <SignaturePad onEnd={save} ref={(ref) => { sigCanvas = ref }} canvasProps={{ width: 300, height: 300}}/>
            <div onClick={clear}>Effacer</div>
            <TextInput source="signature" style={{display: 'none'}}/>
        </>
    )
}


const RapportCreate = (props) => {

    const type = props.options.type;
    const dataProvider = useDataProvider()

    const [defibs, setDefibs] = useState([]);
    const [defibsLoaded, setDefibsLoaded] = useState(false);

    useEffect(function fetchDefibs() {
        let isMounted = true;
        dataProvider.getList('defibrillateurs/interventions/'+type, {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }
        }).then((response) => {
            if (isMounted) {
                response ? setDefibs(response.data) : setDefibs([]);
                setDefibsLoaded(true);
            }
        });
        return () => { isMounted = false };
    }, [defibsLoaded, dataProvider, type]);
    

    var choicesDefibs = [];
    defibs.map(defib => (
        choicesDefibs.push({defibId: defib.id, name: defib.site, id: "defib_"+defib.id})
    ));

    return (
        defibsLoaded ? (
            !defibs.length ?
                <Alert severity="warning">Pour pouvoir créer un rapport, vous devez ajouter une demande d'intervention.</Alert>
            :
            <Create {...props} save={false}>
                <TabbedForm submitOnEnter={false} validate={validateCreateRapport}>
                    <FormTab label="Défibrillateur">
                        <SelectInput source="defibId" optionValue="defibId" label="Séléction du défibrillateur" choices={choicesDefibs}/>
                    </FormTab>
                    <FormTab label="Vérification du matériel">
                        
                        <RadioButtonGroupInput source="type" defaultValue="preventive" choices={[
                            { id: 'preventive', name: 'Préventive' },
                            { id: 'corrective', name: 'Corrective' },
                        ]} />

                        <BooleanInput label="Etat général" source="verif_etat" defaultValue={false}/>
                        <BooleanInput label="Voyant / picto / écran défibrillateur" source="verif_voyant" defaultValue={false}/>
                        <BooleanInput label="Messages vocaux" source="verif_messages_vocaux" defaultValue={false}/>
                        <BooleanInput label="Contrôle armoire / signalétique" source="verif_signaletique" defaultValue={false}/>
                        
                        {/* Piles / Batteries */}


                        <BooleanInput label="Validité piles - batterie défibrillateur" source="verif_piles_valid" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.verif_piles_valid ? [
                                <TextInput label="Lot de piles / batteries" source="verif_piles_lot"/>,
                                <DateInput label="Date péremption des piles / batteries" source="verif_piles_date"/>,
                            ] : null }
                        </FormDataConsumer>


                        <BooleanInput label="Changement piles - batterie défibrillateur" source="change_piles" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.change_piles ? [
                                <div>
                                    <SelectInput source="change_piles_motif" label="Motif changement" choices={[
                                        {id: 0, name: 'Motif 1'},
                                        {id: 1, name: 'Motif 2'},
                                        {id: 2, name: 'Motif 3'},
                                        {id: 3, name: 'Motif 4'},
                                    ]}/>
                                </div>,
                                <TextInput label="Lot de piles / batteries" source="change_piles_lot"/>,
                                <DateInput label="Date péremption des piles / batteries" source="change_piles_date"/>
                            ] : null }
                        </FormDataConsumer>

                        {/* Electrodes Adultes */}

                        <BooleanInput label="Validité éléctrodes adultes" source="verif_elect_adultes_valid" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.verif_elect_adultes_valid ? [
                                <TextInput label="Lot d'éléctrodes adultes" source="verif_elect_adultes_lot"/>,
                                <DateInput label="Date péremption éléctrodes adultes" source="verif_elect_adultes_date"/>,
                            ] : null }
                        </FormDataConsumer>

                        <BooleanInput label="Changement éléctrodes adultes" source="change_elect_adultes" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.change_elect_adultes ? [
                                <div>
                                    <SelectInput source="change_elect_adultes_motif" label="Motif changement" choices={[
                                        {id: 0, name: 'Motif 1'},
                                        {id: 1, name: 'Motif 2'},
                                        {id: 2, name: 'Motif 3'},
                                        {id: 3, name: 'Motif 4'},
                                    ]}/>
                                </div>,
                                <TextInput label="Lot d'éléctrodes adultes" source="change_elect_adultes_lot"/>,
                                <DateInput label="Date péremption éléctrodes adultes" source="change_elect_adultes_date"/>
                            ] : null }
                        </FormDataConsumer>

                        {/* Electrodes Enfants */}

                        <BooleanInput label="Validité éléctrodes enfants" source="verif_elect_enfants_valid" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.verif_elect_enfants_valid ? [
                                <TextInput label="Lot d'éléctrodes enfants" source="verif_elect_enfants_lot"/>,
                                <DateInput label="Date péremption éléctrodes enfants" source="verif_elect_enfants_date"/>,
                            ] : null }
                        </FormDataConsumer>


                        <BooleanInput label="Changement Validité éléctrodes enfants" source="change_elect_enfants" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.change_elect_enfants ? [
                                <div>
                                    <SelectInput source="change_elect_enfants_motif" label="Motif changement" choices={[
                                        {id: 0, name: 'Motif 1'},
                                        {id: 1, name: 'Motif 2'},
                                        {id: 2, name: 'Motif 3'},
                                        {id: 3, name: 'Motif 4'},
                                    ]}/>
                                </div>,
                                <TextInput label="Lot d'éléctrodes enfants" source="change_elect_enfants_lot"/>,
                                <DateInput label="Date péremption éléctrodes enfants" source="change_elect_enfants_date"/>
                            ] : null }
                        </FormDataConsumer>

                        {/* Piles Armoires */}    

                        <BooleanInput label="Etat piles armoire (leds)" source="verif_piles_armoire_valid" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.verif_piles_armoire_valid ? [
                                <TextInput label="Lot de piles armoire" source="verif_piles_armoire_lot"/>,
                                <DateInput label="Date péremption des piles armoires" source="verif_piles_armoire_date"/>,
                            ] : null }
                        </FormDataConsumer>

                        <BooleanInput label="Changement piles armoire" source="change_piles_armoire" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.change_piles_armoire ? [
                                <div>
                                    <SelectInput source="change_piles_armoire_motif" label="Motif changement" choices={[
                                        {id: 0, name: 'Motif 1'},
                                        {id: 1, name: 'Motif 2'},
                                        {id: 2, name: 'Motif 3'},
                                        {id: 3, name: 'Motif 4'},
                                    ]}/>
                                </div>,
                                <TextInput label="Lot de piles armoire" source="change_piles_armoire_lot"/>,
                                <DateInput label="Date péremption des piles armoire" source="change_piles_armoire_date"/>
                            ] : null }
                        </FormDataConsumer>

                        <BooleanInput label="Remplacement du défibrillateur" source="remplacement_defibrillateur" defaultValue={false}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) => formData.remplacement_defibrillateur ? 
                                <TextInput label="Motif remplacement" source="remplacement_motif" multiple />
                            : null }
                        </FormDataConsumer>


                        <TextInput label="Observations" source="verif_observations" multiple />

                        <BooleanInput label="Conformité" source="conformite" defaultValue={false}/>
                        <BooleanInput label="Opérationnel" source="operationnel" defaultValue={false}/>
                        <BooleanInput label="Renseignement Vignette" source="vignette" defaultValue={false}/>

                    </FormTab>
                    <FormTab label="Client">
                        <TextInput label="Nom du Client" source="nom_client"/>
                        <TextInput label="Fonction du Client" source="fonction_client"/>
                        <TextInput label="Commentaire du client" source="commentaire_client" multiline/>
                        <Signature/>
                    </FormTab>
                </TabbedForm>
            </Create>
        ) : null
    )
};

export default RapportCreate;