import React from 'react';
import { 
    Create,
    SimpleForm, 
    RadioButtonGroupInput,
    TextInput,
} from 'react-admin';

import {validateSociete} from './SocieteValidator';

const SocieteCreate = (props) => (
    
    <Create {...props}>
        <SimpleForm validate={validateSociete}>
			<TextInput source="nom" label="Nom de la société"/>
			
			<TextInput source="userSociete.nom" label="Nom"/>
			<TextInput source="userSociete.prenom" label="Prénom"/>
			<RadioButtonGroupInput source="userSociete.civilite" label="Civilité" choices={[
					{ id: '0', name: 'Monsieur' },
					{ id: '1', name: 'Madame' },
			]} />
			<TextInput source="adresse1" label="Adresse 1"/>
			<TextInput source="adresse2" label="Adresse 2"/>
			<TextInput source="cp" label="Code Postal"/>
			<TextInput source="ville" label="Ville"/>
			<TextInput source="pays" label="Pays"/>
			<TextInput source="tel" label="Téléphone portable"/>
			<TextInput source="userSociete.email" label="Email"/>
        </SimpleForm>
    </Create>
);

export default SocieteCreate;