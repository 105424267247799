import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Loading from "../../components/Loading";



const useStyles = makeStyles({
	container: {
		marginBottom: '2em',
	},
	table: {
		// minWidth: 650,
	  },
	tableHeader: {
		paddingBottom: '.3em',
	},
})

const HistoDashboard = (props) => {

    let data = props.data;
	let title = props.title;
	let emptyMessage = props.empty;
	let isLoaded = props.isLoaded;
	let hasData = data.length > 0 ? true : false;

	const classes  = useStyles();

	return (
		<div className={classes.container}>
			<Typography className={classes.tableHeader} variant="h5" component="h2">
				{title}
			</Typography>
			{hasData ? (
				isLoaded ? (
					<TableContainer component={Paper} square={true}>
						<Table className={classes.table} aria-label="simple table" >
							<TableHead>
								<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Site	</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Assigné à</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map(k => (
								<TableRow key={k.id}>
									<TableCell component="th" scope="data">
									{new Date(k.created).toLocaleDateString()}
									</TableCell>
									<TableCell>{k.defibrillateur.site}</TableCell>
									<TableCell>{k.type}</TableCell>
									<TableCell>{k.attribution}</TableCell>
								</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : <Loading />
			) : emptyMessage}

		</div>
	)
}

export default HistoDashboard;