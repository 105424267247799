import React from 'react';
import { Resource } from 'react-admin';

import { Users } from '../containers/users/index';
import { Societe } from '../containers/societes/index';
import { Interventions } from '../containers/interventions/index';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie, faUserCog, faToolbox } from '@fortawesome/free-solid-svg-icons'


const SocieteResources = [
    <Resource 
        options={{expose: false, key : 'societes', label: 'Ma societe', icon: <FontAwesomeIcon icon={faUserTie} />}} 
        name="societes" 
        {...Societe}
    />,
    <Resource 
        options={{expose : true, key : 'users_technicien', label: 'Mes techniciens', type: 'technicien', icon: <FontAwesomeIcon icon={faUserCog}/>}} 
        name="users/technicien" 
        {...Users}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_processing', type: 'processing', label: 'À attribuer', 
            icon: <FontAwesomeIcon icon={faToolbox}/>
        }} 
        name="interventions/processing" 
        {...Interventions}
    />,
];

export default SocieteResources;