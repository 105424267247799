import React from 'react';
import { useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


const DefibrillateurPdf = (props) => {

	const dataProvider = useDataProvider();
	let idDefib;

	if(typeof(props.data) != 'undefined'){
		idDefib = props.data.id
	}

	const handleClick = (e) => {

		dataProvider.getOne('defibrillateurs/pdf', {id: idDefib})
		.then((response) => {
			let base64 = response.data.base64Pdf;
			const binaryString = window.atob(base64);
			const len = binaryString.length;
			const bytes = new Uint8Array(len);
			for (let i = 0; i < len; ++i) {
				bytes[i] = binaryString.charCodeAt(i);
			}
			let blob = new Blob([bytes], { type: 'application/pdf' });
			let url = URL.createObjectURL(blob);
			
			let link = document.createElement("a")
			link.setAttribute('id', 'lienpdf');
			link.setAttribute('href', url);
			link.setAttribute('download', `RapportDefibrillateur${idDefib}`);
			document.body.appendChild(link)
			return link;
		}).then((link) => {link.click();})
		.catch(error => error);
	}
	return (
		<>
			<Button startIcon={<PictureAsPdfIcon />} onClick={handleClick} color="primary" size="small">Télécharger</Button>
		</>
	)
}

export default DefibrillateurPdf;