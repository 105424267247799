import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserShow from './UserShow';


export const Users = {
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    show: UserShow,
}