// in src/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat,faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


import CardDashboard from '../CardDashboard';
import HistoDashboard from '../HistoDashboard';
import MapDashboard from '../MapDashboard';

const ClientRefDashboard = () => {

    const dataProvider = useDataProvider();

    const [alerts, setAlerts] = useState([]);
    const [alertsLoaded, setAlertsLoaded] = useState(false);

    const [defibs, setDefibs] = useState([]);
    const [defibsLoaded, setDefibsLoaded] = useState(false);

    const [interventions, setInterventions] = useState([]);
    const [intervLoaded, setIntervLoaded] = useState(false);

    useEffect(function fetchDefibs(){
        let isMounted = true;
        dataProvider.getList('defibrillateurs', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 10000 }
        }).then((response) => {
            if (isMounted) {
                response ? setDefibs(response.data) : setDefibs([]);
                setDefibsLoaded(true);
            }
        });
        return () => { isMounted = false };
    }, [defibsLoaded, dataProvider]);

    useEffect(function fetchInProcessInterventions(){
        let isMounted = true;
        dataProvider.getList('interventions/inprocess', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }
        }).then((response) => {
            if (isMounted) {
                response ? setAlerts(response.data) : setAlerts([]);
                setAlertsLoaded(true);
            }
        });
        return () => { isMounted = false };
    }, [alertsLoaded, dataProvider]);

    useEffect(function fetchInProcessInterventions(){
        let isMounted = true;
        dataProvider.getList('interventions', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }
        }).then((response) => {
            if (isMounted) {
                response ? setInterventions(response.data) : setInterventions([]);
                setIntervLoaded(true);
            }
        });
        return () => { isMounted = false };
    }, [intervLoaded, dataProvider]);
    
    return  (
       
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
        >
            <Grid item xs={12} lg={6} >
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <CardDashboard 
                            cardData={defibs}
                            cardTitle="Defibrillateurs"
                            cardLink="defibrillateurs"
                            cardIcon={<FontAwesomeIcon icon={faHeartbeat}/>}
                            isLoaded={defibsLoaded}
                            cardTheme="white" 
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <CardDashboard
                            cardData={alerts}
                            cardTitle="Alertes"
                            cardLink="interventions/processing"
                            cardIcon={<FontAwesomeIcon icon={faExclamationTriangle}/>}
                            isLoaded={alertsLoaded}
                            cardTheme="green"
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <HistoDashboard data={alerts} title="Interventions en cours" empty="Aucune intervention en cours" isLoaded={alertsLoaded}/>
                </Grid>
                <Grid item>
                    <HistoDashboard data={interventions} title="Toutes les interventions" empty="Aucune intervention" isLoaded={intervLoaded}/>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={5}>
                <MapDashboard defibs={defibs} isLoaded={defibsLoaded}/>
            </Grid>
        </Grid>
    )
};

export default ClientRefDashboard;