

const local = {
    url: {
        HOST : 'http://localhost:8000',
        API : 'http://localhost:8000/api',
        TOKEN : 'http://localhost:8000/token',
        FRONT : 'http://localhost:3000',
    }
}

const dev = {
    url: {
        HOST : 'https://serenys.kokmokapp.com/api/public',
        API : 'https://serenys.kokmokapp.com/api/public/api',
        TOKEN : 'https://serenys.kokmokapp.com/token',
        FRONT : 'https://serenys.kokmokapp.com'
    }
}

const prod = {
    url: {
        HOST : 'https://app.serenys.eu/api/public',
        API : 'https://app.serenys.eu/api/public/api',
        TOKEN : 'https://app.serenys.eu/token',
        FRONT : 'https://app.serenys.eu'
    }
}

function getConfig (){
    let myEnv = '';
    switch(process.env.REACT_APP_ENV){
        case 'local':
            myEnv = local;
            break;
        case 'dev':
            myEnv = dev;
            break;
        case 'prod':
            myEnv = prod;
            break;
        default:
            myEnv = dev;
            break;
    }
    return myEnv;
}

const config = getConfig();

export default config;
