import ClientList from './ClientList';
import ClientCreate from './ClientCreate';
import ClientEdit from './ClientEdit';
import ClientShow from './ClientShow';


export const Client = {
    edit: ClientEdit,
    show: ClientShow,
}

export const Clients = {
    list: ClientList,
    create: ClientCreate,
    edit: ClientEdit,
    show: ClientShow,
}