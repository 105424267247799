import React, {useEffect, useState} from 'react';
import { 
    useDataProvider,
    Create,
    SimpleForm, 
    SelectInput,
    TextInput
} from 'react-admin';
import {validateCreateIntervention} from './InterventionValidator';
import Alert from '@material-ui/lab/Alert';


const InterventionCreate = (props) => {

    const role = props.permissions;

    const dataProvider = useDataProvider()
    const [defibs, setDefibs] = useState([]);
    const [techs, setTechs] = useState([]);
    const [societes, setSocietes] = useState([]);
    
    const [defibsLoaded, setDefibsLoaded] = useState(false);
    const [techsLoaded, setTechsLoaded] = useState(false);
    const [societesLoaded, setSocietesLoaded] = useState(false);
    
    var choicesDefibs = [];
    var choicesAssign= [];

    let isAdmin = role === 'ROLE_ADMIN' ? true : false;

    
    useEffect(function fetchDefibs() {
        let isMounted = true;
        dataProvider.getList('defibrillateurs/no-interventions', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }
        }).then((response) => {
            if (isMounted) {
                response ? setDefibs(response.data) : setDefibs([]);
                setDefibsLoaded(true);
            }
        });
        if(isAdmin){
            dataProvider.getList('societes', {
                filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }, group: false
            }).then((response) => {
                if (isMounted) {
                    setSocietes(response.data);
                    setSocietesLoaded(true);
                }
            });
            dataProvider.getList('users/technicien', {
                filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }, group: false
            }).then((response) => {
                if (isMounted) {
                    setTechs(response.data);
                    setTechsLoaded(true);
                }
            });
        }
        return () => { isMounted = false };
    }, [defibsLoaded, techsLoaded, societesLoaded, dataProvider, isAdmin]);

    defibs.map(defib => (
        choicesDefibs.push({defibId: defib.id, name: defib.site, key: "defib_"+defib.id})
    ));
    choicesAssign.push({id: 'technicien', name: 'Techniciens', disabled: true})
    techs.map(tech => (
        choicesAssign.push({id: tech.id, name: tech.nom})
    ));
    choicesAssign.push({id: 'societe', name: 'Sociétés', disabled: true})
    societes.map(societe => (
        choicesAssign.push({id: 'societe_' + societe.id, name: societe.nom, type: 'societe'})
    ));

    const optionRenderer = choice => {
        if(!('disabled' in choice)){
            return "\u00A0".repeat(3) + choice.name;
        } else {
            return choice.name;
        }
    }

    return(   
        defibsLoaded ? (
            !defibs.length ?
                <Alert severity="warning">
                    Demande d'intervention impossible. <br/>
                    Vérifiez que vos défibrillateurs n'ont pas d'interventions en cours, ou ajoutez un nouveau défibrillateur.
                </Alert>
            :
            <Create {...props} title="Demande d'intervention sur site">
                <SimpleForm validate={validateCreateIntervention}>
                    <SelectInput key="choices" optionValue="defibId" source="defibId" label="Choisir un Site" choices={choicesDefibs}/>
                    <TextInput source="description"  multiline/>
                    {role === 'ROLE_ADMIN' ? [
                        <TextInput source="comment_serenys" label="Commentaire Serenys" multiline/>,
                        <SelectInput source="type" label="Type" choices={[
                            {id: 'installation', name : 'Installation'},
                            {id: 'maintenance', name : 'Maintenance'}
                        ]} required/>,
                        <SelectInput source="attribution" label="Assigné à" choices={choicesAssign} optionText={optionRenderer}/>
                    ] : null}

                </SimpleForm>
            </Create>
        ) : null
    );
}

export default InterventionCreate;