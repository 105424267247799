import React, {useState} from 'react';
import config from '../config';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Typography from '@material-ui/core/Typography';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import Loading from "./Loading";


const ResetPwdForm = (props) => {
	let token = props.token;

    let [flashMessage, setFlashMessage] = useState('');
    let [openSnackbar, setOpenSnackBar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


	function handleClose(){
		setOpenSnackBar(false);
	}

    function handleSubmit (event) {
        event.preventDefault();
        let password = event.target.password.value;
        setIsLoading(true);

        fetch(config.url.HOST+'/reset', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
				token: token, 
				password: password 
			}),            
        }).then(response => {
            setIsLoading(false);
            if(response.status === 200){
                setOpenSnackBar(true);
				setFlashMessage('Le mot de passe a bien été réinitialisé');
				window.location.href = '/';
            } else {
                setOpenSnackBar(true);
                setFlashMessage('Une erreur est survenue');
            }
        }).catch(err => err);
    }
	return (
		<div>
            {!isLoading ? (
                <>
                <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleClose}>
		    		<SnackbarContent message={flashMessage}></SnackbarContent>
		    	</Snackbar>
                <Typography variant="h3" component="h3">Réinitialiser le mot de passe</Typography>
                <form onSubmit={handleSubmit}>
                	<TextField required name="password" type="password" label="Mot de passe" />
                	<Button variant="contained" color="primary" type='submit'>Valider</Button>
                </form>
                </>
            ): <Loading/>}
		</div>
	)
}

export default ResetPwdForm;