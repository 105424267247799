import SocieteList from './SocieteList';
import SocieteCreate from './SocieteCreate';
import SocieteEdit from './SocieteEdit';
import SocieteShow from './SocieteShow';


export const Societe = {
    edit: SocieteEdit,
    show: SocieteShow,
}

export const Societes = {
    list: SocieteList,
    create: SocieteCreate,
    edit: SocieteEdit,
    show: SocieteShow,
}