import React from 'react';
import { 
    EditView,
    EditController,
    Toolbar,
    SimpleForm,
    SaveButton, 
    TextInput,
} from 'react-admin';

import {validateSociete} from './SocieteValidator';

const SocieteEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const SocieteEdit = (props) => {
    return(
        <>
        <EditController {...props}>
            {controllerProps => 

                <EditView {...props} {...controllerProps}>
                    <SimpleForm toolbar={<SocieteEditToolbar />} validate={validateSociete}>
                        <TextInput source="nom" label="Société"/>
                        <TextInput source="adresse1" label="Adresse 1"/>
                        <TextInput source="adresse2" label="Adresse 2"/>
                        <TextInput source="cp" label="Code Postal"/>
                        <TextInput source="ville" label="Ville"/>
                        <TextInput source="pays" label="Pays"/>
                        <TextInput source="tel"/>
                    </SimpleForm>
                </EditView>
            }
        </EditController>
        </>
    );
}

export default SocieteEdit;