export const validateUsers = (values) => {
    const input = [
		'email',
		'civilite',
		'nom',
		'prenom',
    ];
    const errors = {};

    input.forEach((item, index) => {
        if(!(item in values)) {
            errors[item] = ['errors.global.required'];
        }
    });
    
    return errors;
}

