import React from 'react';
import { 
    Create,
    SimpleForm, 
    ImageField,
    ImageInput,
    RadioButtonGroupInput,
    TextInput,
    required
} from 'react-admin';

import {validateClient} from './ClientValidator';

const ClientCreate = (props) => (
    
    <Create {...props}>
        <SimpleForm validate={validateClient} redirect="/users/referent/create">
            <TextInput source="nom" label="Client"/>
            <RadioButtonGroupInput source="user.civilite" label="Civilité" choices={[
                { id: '0', name: 'Monsieur' },
                { id: '1', name: 'Madame' },
            ]} validate={[required()]}/>
            <TextInput source="user.nom" label="Nom" validate={[required()]}/>
            <TextInput source="user.prenom" label="Prénom" validate={[required()]}/>
            <TextInput source="adresse1" label="Adresse 1"/>
            <TextInput source="adresse2" label="Adresse 2"/>
            <TextInput source="adresse3" label="Complément d'adresse"/>
            <TextInput source="cp" label="Code Postal"/>
            <TextInput source="ville" label="Ville"/>
            <TextInput source="pays" label="Pays"/>
            <TextInput source="tel" label="Téléphone portable"/>
            <TextInput source="user.email" label="Email" validate={[required()]}/>
            <ImageInput source="logo" label="Related pictures" accept="image/*"> 
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);

export default ClientCreate;