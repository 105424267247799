// in src/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import HistoDashboard from '../HistoDashboard';
import CardDashboard from '../CardDashboard';

const SocieteDashboard = () => {

    const dataProvider = useDataProvider();

    const [alerts, setAlerts] = useState([]);
    const [alertsLoaded, setAlertsLoaded] = useState(false);

    const [techs, setTechs] = useState([]);
    const [techsLoaded, setTechsLoaded] = useState(false);


    useEffect(function fetchTechs(){
        let isMounted = true;
        dataProvider.getList('users/technicien', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }
        }).then((response) => {
            if (isMounted) {
                response ? setTechs(response.data) : setTechs([]);
                setTechsLoaded(true);
            }
        });
        return () => { isMounted = false };
    }, [techsLoaded, dataProvider]);

    useEffect(function fetchClosedInterventions(){
        let isMounted = true;
        dataProvider.getList('interventions/inprocess', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }
        }).then((response) => {
            if (isMounted) {
                response ? setAlerts(response.data) : setAlerts([]);
                setAlertsLoaded(true);
            }
        });
        return () => { isMounted = false };
    }, [alertsLoaded, dataProvider]);

    return  (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
        >
        <Grid item xs={12} lg={8} >
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12} lg={6}>
                    <CardDashboard
                        cardData={techs}
                        cardTitle="Techniciens"
                        cardLink="users/technicien"
                        cardIcon={<FontAwesomeIcon icon={faExclamationTriangle}/>}
                        isLoaded={alertsLoaded}
                        cardTheme="white"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CardDashboard
                        cardData={alerts}
                        cardTitle="Alertes"
                        cardLink="interventions/processing"
                        cardIcon={<FontAwesomeIcon icon={faExclamationTriangle}/>}
                        isLoaded={alertsLoaded}
                        cardTheme="green"
                    />
                    </Grid>
                </Grid>
                <Grid item>
                    <HistoDashboard data={alerts} title="Dernières alertes" empty="Aucune alerte" isLoaded={alertsLoaded}/>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default SocieteDashboard;