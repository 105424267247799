import React, {useState, useEffect} from 'react';
import { 
    Show, 
    TabbedShowLayout,
    Tab,
    FunctionField,
    TextField,
    EmailField,
    EditButton,
    TopToolbar,  
} from 'react-admin';
import DefibrillateurPdf from './DefibrillateurPdf'
import globalStyles from '../../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';



const DefibrillateurShowAction = ({ basePath, data, resource }) => {

    const [fetchData, setFetchData] = useState(data);

    useEffect(() => {    
        setFetchData(data);
    }, [fetchData, data]);

    return(
        <TopToolbar>
            <EditButton basePath={basePath} record={data} />
            <DefibrillateurPdf data={fetchData}/>
        </TopToolbar>
    )
}

const DefibrillateurShow = (props) => {
    const checked = <FontAwesomeIcon icon={faCheckCircle} />
    const unchecked = <FontAwesomeIcon icon={faTimesCircle} />
    const classes = globalStyles();

    return (
    <>
        <Show className={classes.showLayout} actions={<DefibrillateurShowAction />} {...props}>
            <TabbedShowLayout>
                <Tab label="Client">
                    <TextField source="client.nom" label="Client"/>
                    <FunctionField render={record => record.client.tel ? record.client.tel : '-'} label="Téléphone"/>
                </Tab>
                <Tab label="Localisation">
                    <FunctionField render={record => record.site ? record.site : '-'} label="Nom du site"/>
                    <FunctionField render={record => record.adresse ? record.adresse : '-'} label="Adresse"/>
                    <FunctionField render={record => record.ville ? record.ville : '-'} label="Ville"/>
                    <FunctionField render={record => record.cp ? record.cp : '-'} label="Postal"/>
                    <FunctionField render={record => record.pays ? record.pays : '-'} label="Pays"/>
                </Tab>
                <Tab label="Référent">
                    <FunctionField render={record => record.referent.civilite === 0 ? 'Monsieur' : 'Madame'} label="Civilité" />
                    <FunctionField render={record => `${record.referent.nom} ${record.referent.prenom}`} label="Référent" />
                    <EmailField source="referent.email" label="Email"/>
                    <FunctionField render={record => record.referent.tel ? record.referent.tel : '-'} label="Téléphone"/>

                </Tab>
                <Tab label="Défibrillateur">
                    <FunctionField render={record => record.marque ? record.marque : '-'} label="Marque"/>
                    <FunctionField render={record => record.modele ? record.modele : '-'} label="Modèle"/>
                    <FunctionField render={record => record.num_serie ? record.num_serie : '-'} label="Numéro de série"/>
                    <FunctionField label="Etat" render={record => record.etat === 1 ? (<span className={classes.checked}>{checked}</span>) : (<span className={classes.unchecked}>{unchecked}</span>) } />
                    <FunctionField render={record => record.electrodes_adultes_lot ? record.electrodes_adultes_lot : '-'} label="Lot d'éléctrodes adultes"/>
                    <FunctionField render={record => record.electrodes_adultes_date ? record.electrodes_adultes_date : '-'} label="Date péremption éléctrodes adultes"/>
                    <FunctionField render={record => record.electrodes_enfants_lot ? record.electrodes_enfants_lot : '-'} label="Lot d'éléctrodes enfants"/>
                    <FunctionField render={record => record.electrodes_enfants_date ? record.electrodes_enfants_date : '-'} label="Date péremption éléctrodes enfants"/>
                    <FunctionField render={record => record.piles_lot ? record.piles_lot : '-'} label="Lot de piles / batteries"/>
                    <FunctionField render={record => record.piles_date ? record.piles_date : '-'} label="Date péremption piles / batteries"/>
                    <FunctionField render={record => record.armoire_type ? record.armoire_type : '-'} label="Type d'armoire"/>
                    <FunctionField render={record => record.connexion ? record.connexion : '-'} label="Connexion"/>                    
                    <FunctionField render={record => record.num_serie_connexion ? record.num_serie_connexion : '-'} label="N° série module connexion"/>
                    <FunctionField render={record => record.installation_date ? record.installation_date : '-'} label="Date installation"/>
                    <FunctionField render={record => record.maintenance_date ? record.maintenance_date : '-'} label="Date dernière maintenance"/>
                    <FunctionField render={record => record.maintenance_next_date ? record.maintenance_next_date : '-'} label="Date limite prochaine maintenance"/>
                    <FunctionField label="Alerte" render={record => record.alert === 1 ? (<span className={classes.checked}>{checked}</span>) : (<span className={classes.unchecked}>{unchecked}</span>) } />
                </Tab>
                <Tab label="Formation">
                    <FunctionField label="Formation effectuée" render={ record => record.formation === 1 ? 'Oui' : 'Non'}/>
                    <FunctionField render={record => record.formation_date ? record.formation_date : '-'} label="Date dernière formation"/>
                </Tab>
                <Tab label="Autre">
                    <FunctionField render={record => record.created ? record.created : '-'} label="Ajouté le"/>
                    <FunctionField render={record => record.updated ? record.updated : '-'} label="Modifié le"/>
                </Tab>
            </TabbedShowLayout>
        </Show>        
    </>
)};

export default DefibrillateurShow;