import React, {useEffect, useState } from 'react';
import { 
    useDataProvider,
    Edit,
    SimpleForm, 
    DateField,
    TextField,
    SelectInput,
    TextInput,
} from 'react-admin';
import {validateEditIntervention} from '../InterventionValidator';



const InterventionEditAdmin = (props) => {
    const dataProvider = useDataProvider()
    const [techs, setTechs] = useState([]);
    const [societes, setSocietes] = useState([]);

    const [techsLoaded, setTechsLoaded] = useState(false);
    const [societesLoaded, setSocietesLoaded] = useState(false);
    
    useEffect(function fetchDefibs() {
        let isMounted = true;
        
        dataProvider.getList('societes', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }, group: false
        }).then((response) => {
            if (isMounted) {
                response ? setSocietes(response.data) : setSocietes([]);
                setSocietesLoaded(true);
            }
        });
        dataProvider.getList('users/technicien', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }, group: false
        }).then((response) => {
            if (isMounted) {
                response ? setTechs(response.data) : setTechs([]);
                setTechsLoaded(true);
            }
        })
        return () => { isMounted = false };
    }, [techsLoaded, societesLoaded, dataProvider]);

    let choicesAssign = [];

    choicesAssign.push({id: 'technicien', name: 'Techniciens', disabled: true})
    techs.map(tech => (
        choicesAssign.push({id: tech.id, name: tech.nom})
    ));
    choicesAssign.push({id: 'societe', name: 'Sociétés', disabled: true})
    societes.map(societe => (
        choicesAssign.push({id: 'societe_'+societe.id, name: societe.nom, type: 'societe'})
    ));

    const optionRenderer = choice => {
        if(!('disabled' in choice)){
            return "\u00A0".repeat(3) + choice.name;
        } else {
            return choice.name;
        }
    }
    return(
        <Edit {...props}>
            <SimpleForm validate={validateEditIntervention} redirect="/interventions">
                <TextField source="defibrillateur.site" label="Site"/>
                <DateField source="created" label="Ajouté le"/>
                <TextInput source="description" label="Commentaire demandeur" disabled/>
                <TextInput source="comment_serenys" label="Commentaire Serenys" multiline/>
                <TextInput source="comment_technicien" label="Commentaire Technicien" disabled/>
                <SelectInput source="type" label="Type" choices={[
                    {id: 'installation', name : 'Installation'},
                    {id: 'maintenance', name : 'Maintenance'}
                ]}/>
                <SelectInput source="statut" label="Statut de l'intervention" choices={[
                    {id: 'new', name : 'Nouveau', 'disabled' : true},
                    {id: 'processing', name : 'A traiter', 'selected' : true},
                    {id: 'closed', name : 'Fermé'}
                ]}/>
                <SelectInput source="attribution" label="Assigné à" choices={choicesAssign} optionText={optionRenderer}/>
                
            </SimpleForm>
        </Edit>
    );
}

export default InterventionEditAdmin;