import React from 'react';
import { Link } from 'react-router-dom';

import {Button} from 'react-admin';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { MyColors } from '../../components/MyTheme';
import Loading from "../../components/Loading";

const useStyles = makeStyles({
	card: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		overflow: 'inherit',
		padding: 16,
		minHeight: 52,
		marginBottom: 30,				
		'&.green': {
			color: 'white !important',
			backgroundColor: MyColors.darkgreen,
			"& svg" : {
				color: 'white !important',
			},
			"& .greenIcon": {
				"& svg": {
					color: "#ffffff",
				}
			},
			"& .greenTitle": {
				color: "#ffffff",
			},
			"& .greenNumber": {
				color: "#ffffff",
			}
		},
		'&.white': {
			"& .whiteIcon": {
				"& svg": {
					color: MyColors.lightgreen,
				}
			},
			"& .whiteTitle": {
				color: MyColors.lightgreen,
			},
			"& .whiteNumber": {
				color: MyColors.lightgreen,
			},
			"& .whiteArrow": {
				color: MyColors.lightgreen,
			},
		}
	},
	cardTitle: {
		fontSize: '20px',
	},
	cardNumber: {
		fontSize: '40px',
		fontWeight: '600',
		lineHeight: '1',
	},
	cardArrow: {
		height: 'auto',
		width: '31px !important',
	},
	cardIcon: {
		'& svg':{
			height: 'auto',
			width: '50px !important' ,
		}
	},
});

const CardDashboard = (props) => {

	const data = props.cardData;
    const cardIcon = props.cardIcon;
	const title = props.cardTitle;
	const link = props.cardLink;
	const isLoaded = props.isLoaded;
	const theme = props.cardTheme;
	const length = data.length;
	const classes = useStyles();
	
	return (
		<div className={classes.container}>
            <Card className={`${classes.card} ${theme}`} square={true}>
				<div className={`${classes.cardIcon} ${theme+"Icon"}`}>{cardIcon}</div>
				<div>
					{isLoaded ? 
						<Typography className={`${classes.cardNumber} ${theme+"Number"}`}>
							{length} 
						</Typography>
					: <Loading />}
					<Typography variant="h3" component="h3" className={`${classes.cardTitle} ${theme+"Title"}`}>
						{title}
					</Typography>
				</div>
				<Button
  				  component={Link}
  				  to={{
  				    pathname: "/"+link,
  				  }}>
				  <FontAwesomeIcon icon={faChevronCircleRight} className={`${classes.cardArrow} ${theme+"Arrow"}`}/>
				</Button>
				
            </Card>
		</div>
	)
}

export default CardDashboard;