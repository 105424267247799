import config from '../config';
import decodeJwt from 'jwt-decode';

const authProvider = {
    login: ({username, password}) => {
        const request = new Request(config.url.API+'/login_check', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json'}),
        });
        return fetch(request)
        .then(response => {
            if(response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(({token}) => {
            const decodedToken = decodeJwt(token);
            if(!decodedToken.active) {
                return Promise.reject();
            }
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('permissions', decodedToken.roles);
            sessionStorage.setItem('userId', decodedToken.userId);
            if(decodedToken.clientId > 0){
                sessionStorage.setItem('clientId', decodedToken.clientId);
            }
            if(decodedToken.societeId > 0){
                sessionStorage.setItem('societeId', decodedToken.societeId);
            }
        })
    },
    logout: () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('permissions');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('clientId');
        sessionStorage.removeItem('societeId');
        return Promise.resolve();
    },
    checkAuth: (error) => {
        const status = error.status;
        if(sessionStorage.getItem('token')){
            if(status === 401 || status === 403) {
                return Promise.reject();
            } else {
                return Promise.resolve()
            }
        } else {
            return Promise.reject();
        }
    },
    checkError: (error) => {
        const status = error.status;
        if(status === 401 || status === 403) {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('permissions');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('clientId');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        let authorizedPage = 'verification-defibrillateur';
        let parsedUrl = new URL(window.location.href);
        const role = sessionStorage.getItem('permissions');
        if(role){
            return Promise.resolve(role)
        } else {
            return parsedUrl.href.includes(authorizedPage) ?  Promise.resolve() : Promise.reject();
        }        
    }
};

export default authProvider;