import React from 'react';
import { 
    Edit,
    TabbedForm, 
    FormTab, 
    FormDataConsumer,
    BooleanInput,
    RadioButtonGroupInput,
    DateInput,
    TextInput,
    SelectInput,
    ImageField,
    TextField,
    Toolbar,
    SaveButton
} from 'react-admin';
import { useQuery, Loading, Error } from 'react-admin';
import {validateEditRapport} from './RapportValidator';

const RapportEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const RapportEdit = (props) => {
    const type = props.options.type;
    const { data: defibs, loading: defibsQueryLoading, error: defibsQueryError } = useQuery({ 
        type: 'getList',
        resource: 'defibrillateurs/interventions/'+type,
        payload: {pagination: {page: 1, perPage: 10}, sort: {field: 'id', order: 'ASC'}}
    });
    if (defibsQueryLoading) return <Loading />;
    if (defibsQueryError) return <Error />;
    if (!defibs) return null;
    var choicesDefibs = [];

    defibs.map(defib => (
        choicesDefibs.push({id: {id : defib.id}, name: defib.site, key: "defib_"+defib.id})
        ));
    return(
        <Edit {...props}>

            <TabbedForm submitOnEnter={false}  validate={validateEditRapport} toolbar={<RapportEditToolbar />}>
                <FormTab label="Défibrillateur">
                    <TextField source="defibrillateur.client.nom" label="Client"/>
                    <TextField source="defibrillateur.site" label="Défibrillateur"/>
                    <TextField source="defibrillateur.marque" label="Marque"/>
                    <TextField source="defibrillateur.modele" label="Modèle"/>
                    <TextField source="defibrillateur.num_serie" label="Numéro de série"/>
                </FormTab>
                <FormTab label="Vérification du matériel">
                    <RadioButtonGroupInput source="type" choices={[
                        { id: 'preventive', name: 'Préventive' },
                        { id: 'corrective', name: 'Corrective' },
                    ]} />

                    <BooleanInput label="Etat général" source="verif_etat" />
                    <BooleanInput label="Voyant / picto / écran défibrillateur" source="verif_voyant" />
                    <BooleanInput label="Messages vocaux" source="verif_messages_vocaux" />
                    <BooleanInput label="Contrôle armoire / signalétique" source="verif_signaletique" />
                    
                    {/* Piles / Batteries */}


                    <BooleanInput label="Validité piles - batterie défibrillateur" source="verif_piles_valid" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.verif_piles_valid ? [
                            <TextInput label="Lot de piles / batteries" source="verif_piles_lot"/>,
                            <DateInput label="Date péremption des piles / batteries" source="verif_piles_date"/>,
                        ] : null }
                    </FormDataConsumer>


                    <BooleanInput label="Changement piles - batterie défibrillateur" source="change_piles" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.change_piles ? [
                            <div>
                                <SelectInput source="change_piles_motif" label="Motif changement" choices={[
                                    {id: 0, name: 'Motif 1'},
                                    {id: 1, name: 'Motif 2'},
                                    {id: 2, name: 'Motif 3'},
                                    {id: 3, name: 'Motif 4'},
                                ]}/>
                            </div>,
                            <TextInput label="Lot de piles / batteries" source="change_piles_lot"/>,
                            <DateInput label="Date péremption des piles / batteries" source="change_piles_date"/>
                        ] : null }
                    </FormDataConsumer>

                    {/* Electrodes Adultes */}

                    <BooleanInput label="Validité éléctrodes adultes" source="verif_elect_adultes_valid" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.verif_elect_adultes_valid ? [
                            <TextInput label="Lot d'éléctrodes adultes" source="verif_elect_adultes_lot"/>,
                            <DateInput label="Date péremption éléctrodes adultes" source="verif_elect_adultes_date"/>,
                        ] : null }
                    </FormDataConsumer>

                    <BooleanInput label="Changement éléctrodes adultes" source="change_elect_adultes" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.change_elect_adultes ? [
                            <div>
                                <SelectInput source="change_elect_adultes_motif" label="Motif changement" choices={[
                                    {id: 0, name: 'Motif 1'},
                                    {id: 1, name: 'Motif 2'},
                                    {id: 2, name: 'Motif 3'},
                                    {id: 3, name: 'Motif 4'},
                                ]}/>
                            </div>,
                            <TextInput label="Lot d'éléctrodes adultes" source="change_elect_adultes_lot"/>,
                            <DateInput label="Date péremption éléctrodes adultes" source="change_elect_adultes_date"/>
                        ] : null }
                    </FormDataConsumer>

                    {/* Electrodes Enfants */}

                    <BooleanInput label="Validité éléctrodes enfants" source="verif_elect_enfants_valid" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.verif_elect_enfants_valid ? [
                            <TextInput label="Lot d'éléctrodes enfants" source="verif_elect_enfants_lot"/>,
                            <DateInput label="Date péremption éléctrodes enfants" source="verif_elect_enfants_date"/>,
                        ] : null }
                    </FormDataConsumer>


                    <BooleanInput label="Changement Validité éléctrodes enfants" source="change_elect_enfants" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.change_elect_enfants ? [
                            <div>
                                <SelectInput source="change_elect_enfants_motif" label="Motif changement" choices={[
                                    {id: 0, name: 'Motif 1'},
                                    {id: 1, name: 'Motif 2'},
                                    {id: 2, name: 'Motif 3'},
                                    {id: 3, name: 'Motif 4'},
                                ]}/>
                            </div>,
                            <TextInput label="Lot d'éléctrodes enfants" source="change_elect_enfants_lot"/>,
                            <DateInput label="Date péremption éléctrodes enfants" source="change_elect_enfants_date"/>
                        ] : null }
                    </FormDataConsumer>

                    {/* Piles Armoires */}    

                    <BooleanInput label="Etat piles armoire (leds)" source="verif_piles_armoire_valid" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.verif_piles_armoire_valid ? [
                            <TextInput label="Lot de piles armoire" source="verif_piles_armoire_lot"/>,
                            <DateInput label="Date péremption des piles armoires" source="verif_piles_armoire_date"/>,
                        ] : null }
                    </FormDataConsumer>

                    <BooleanInput label="Changement piles armoire" source="change_piles_armoire" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.change_piles_armoire ? [
                            <div>
                                <SelectInput source="change_piles_armoire_motif" label="Motif changement" choices={[
                                    {id: 0, name: 'Motif 1'},
                                    {id: 1, name: 'Motif 2'},
                                    {id: 2, name: 'Motif 3'},
                                    {id: 3, name: 'Motif 4'},
                                ]}/>
                            </div>,
                            <TextInput label="Lot de piles armoire" source="change_piles_armoire_lot"/>,
                            <DateInput label="Date péremption des piles armoire" source="change_piles_armoire_date"/>
                        ] : null }
                    </FormDataConsumer>

                    <BooleanInput label="Remplacement du défibrillateur" source="remplacement_defibrillateur" />
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.remplacement_defibrillateur ? 
                            <TextInput label="Motif remplacement" source="remplacement_motif" multiple />
                        : null }
                    </FormDataConsumer>

                    <TextInput label="Observations" source="verif_observations" multiple />

                    <BooleanInput label="Conformité" source="conformite" />
                    <BooleanInput label="Opérationnel" source="operationnel" />
                    <BooleanInput label="Renseignement Vignette" source="vignette" />

                </FormTab>
                <FormTab label="Client">
                    <TextInput label="Nom du Client" source="nom_client"/>
                    <TextInput label="Fonction du Client" source="fonction_client"/>
                    <TextInput label="Commentaire du client" source="commentaire_client" multiline/>
                    <ImageField label="Signature" source="signature" title="Signature" /> 
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}

export default RapportEdit;