import { fetchUtils } from "react-admin";
//import jsonServerProvider from 'ra-data-json-server';
//import simpleRestProvider from 'ra-data-simple-rest';
import myRestProvider from './myRestProvider';
import config from '../config';


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

if(!url.includes('api')){
    return fetchUtils.fetchJson(url)
}
    const token = sessionStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};
export default myRestProvider(config.url.API, httpClient);





