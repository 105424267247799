import React, {useEffect, useState } from 'react';
import { 
    useDataProvider,
    Edit,
    SimpleForm, 
    DateField,
    TextField,
    SelectInput,
    TextInput,
} from 'react-admin';




const InterventionEditSociete = (props) => {

    const dataProvider = useDataProvider()
    const [techs, setTechs] = useState([]);

    const [techsLoaded, setTechsLoaded] = useState(false);
    
    useEffect(function fetchDefibs() {
        let isMounted = true;
        dataProvider.getList('users/technicien', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }, group: false
        }).then((response) => {
            if (isMounted) {
                setTechs(response.data);
                setTechsLoaded(true);
            }
        });
        return () => { isMounted = false };
    }, [techsLoaded, dataProvider]);

    let choicesTech = [];
    techs.map(tech => (
        choicesTech.push({id: tech.id, name: tech.nom}) 
    ));

    const optionRenderer = choice => {
        if(!('disabled' in choice)){
            return "\u00A0".repeat(3) + choice.name;
        } else {
            return choice.name;
        }
    }
    return(
        <Edit {...props}>
            <SimpleForm>
                <TextField source="defibrillateur.site" label="Site"/>
                <DateField source="created" label="Ajouté le"/>
                <TextInput source="description" label="Commentaire demandeur" disabled/>
                <TextInput source="comment_serenys" label="Commentaire Serenys" disabled/>
                <SelectInput source="attribution" label="Assigné à" choices={choicesTech} optionText={optionRenderer}/>
                <SelectInput source="statut" label="Statut de l'intervention" choices={[
                    {id: 'processing', name : 'En cours', selected: true},
                ]}/>
                
            </SimpleForm>
        </Edit>
    );
}

export default InterventionEditSociete;