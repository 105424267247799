export const validateCreateDefibrillateur = (values) => {
    const input = [
        'clientId', 
        'site', 'adresse', 'ville', 'cp', 'pays',
        'refId',
        'marque', 'modele', 'num_serie', 'electrodes_adultes_lot','electrodes_adultes_date',
        'piles_lot','piles_date','armoire_type', 'piles_armoire_lot', 'piles_armoire_date', 'connexion',
        'installation_date','maintenance_date','etat', 'etat_date', 'maintenance_next_date'

    ];
    const errors = {};

    input.forEach((item, index) => {
        if(!(item in values)) {
            errors[item] = ['errors.global.required'];
        }
    });
    
    return errors;
}

export const validateEditDefibrillateur = (values) => {
    const input = [
        'site', 'adresse', 'ville', 'cp', 'pays',
        'marque', 'modele', 'num_serie', 'electrodes_adultes_lot','electrodes_adultes_date',
        'piles_lot','piles_date','armoire_type', 'piles_armoire_lot', 'piles_armoire_date', 'connexion',
        'installation_date','maintenance_date','etat', 'etat_date', 'maintenance_next_date'

    ];
    const errors = {};

    input.forEach((item, index) => {
        if(!(item in values)) {
            errors[item] = ['errors.global.required'];
        }
    });
    
    return errors;
}

