export const validateSociete = (values) => {
    const input = [
        'nom',
        'tel',
    ];
    const errors = {};

    input.forEach((item, index) => {
        if(!(item in values)) {
            errors[item] = ['errors.global.required'];
        }
    });
    
    return errors;
}

