import React from 'react';
import { 
    List, 
    Datagrid, 
    DateField,
    TextField, 
    EditButton, 
    ShowButton, 
} from 'react-admin';
import globalStyles from '../../styles';
import Empty from '../Empty';


const RapportList = (props) => {
    const classes = globalStyles();
    const role = props.permissions; 
    return (
        <List bulkActionButtons={false} empty={<Empty type="rapport" />} {...props} >
            <Datagrid>
                <DateField label="Date" source="created"/>
                <TextField label="Client" source="defibrillateur.client.nom" />
                <TextField label="Site" source="defibrillateur.site" />
                <TextField label="Marque" source="defibrillateur.marque" />
                <TextField label="Type" source="type"/>
                {role === 'ROLE_ADMIN' ? (
                    <ShowButton className={classes.listButton}/>,
                    <EditButton className={classes.listButton}/> 
                ): null}
            </Datagrid>
        </List>
    )
};

export default RapportList;