export const validateCreateRapport = (values) => {
    const input = [
        'defibId', 'type', 'nom_client', 'signature', 'fonction_client'
    ];
    const errors = {};


    const checkSubInput = [
            {
                input: 'verif_piles_valid', 
                subinput: ['verif_piles_lot','verif_piles_date']
            },
            {
                input: 'verif_elect_adultes_valid', 
                subinput: ['verif_elect_adultes_lot','verif_elect_adultes_date']
            },
            {
                input: 'verif_elect_enfants_valid', 
                subinput: ['verif_elect_enfants_lot','verif_elect_enfants_date']
            },
            {
                input: 'change_piles', 
                subinput: ['change_piles_motif','change_piles_lot','change_piles_date']
            },
            {
                input: 'change_elect_adultes', 
                subinput: ['change_elect_adultes_motif','change_elect_adultes_lot','change_elect_adultes_date']
            },
            {
                input: 'change_elect_enfants', 
                subinput: ['change_elect_enfants_motif','change_elect_enfants_lot','change_elect_enfants_date']
            }
    ]



    checkSubInput.forEach((item, index) => {
        var sub = item.subinput;
        if(item.input in values & values[item.input]){
            sub.forEach((subitem, subindex) => {
                if(!(subitem in values)){
                    errors[subitem] = ['errors.global.required'];
                }
            });
        }
    });
    
    input.forEach((item, index) => {
        if(!(item in values)) {
            errors[item] = ['errors.global.required'];
        }
    });
    return errors;
}


export const validateEditRapport = (values) => {
    const input = [
        'nom_client', 'signature', 'fonction_client'
    ];
    const errors = {};


    const checkSubInput = [
            {
                input: 'verif_piles_valid', 
                subinput: ['verif_piles_lot','verif_piles_date']
            },
            {
                input: 'verif_elect_adultes_valid', 
                subinput: ['verif_elect_adultes_lot','verif_elect_adultes_date']
            },
            {
                input: 'verif_elect_enfants_valid', 
                subinput: ['verif_elect_enfants_lot','verif_elect_enfants_date']
            },
            {
                input: 'change_piles', 
                subinput: ['change_piles_motif','change_piles_lot','change_piles_date']
            },
            {
                input: 'change_elect_adultes', 
                subinput: ['change_elect_adultes_motif','change_elect_adultes_lot','change_elect_adultes_date']
            },
            {
                input: 'change_elect_enfants', 
                subinput: ['change_elect_enfants_motif','change_elect_enfants_lot','change_elect_enfants_date']
            }
    ]



    checkSubInput.forEach((item, index) => {
        var sub = item.subinput;
        if(item.input in values & values[item.input]){
            sub.forEach((subitem, subindex) => {
                if(!(subitem in values)){
                    errors[subitem] = ['errors.global.required'];
                }
            });
        }
    });
    
    input.forEach((item, index) => {
        if(!(item in values)) {
            errors[item] = ['errors.global.required'];
        }
    });
    return errors;
}

