import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {CreateButton} from 'react-admin';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: 0.5,
            margin: '0 1em',
            color: 'inherit'
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
    }),
    { name: 'RaEmpty' }
);

const Empty = (props) => {
    const {basePath, type } = props;
    const classes = useStyles(props);
    return (
        <>
            <Alert severity="info">Il n'y a pas encore de {type}</Alert>
            <div className={classes.toolbar}>
                <CreateButton variant="contained" basePath={basePath} />
            </div>
        </>
    );
};

export default Empty;