import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    EditButton,
    ShowButton,
} from 'react-admin';
import DeactivateSociete from './DeactivateSociete';
import Empty from '../Empty';

const SocieteBulkActions = props => (
    <DeactivateSociete {...props}/>
);
const SocieteList = (props) => {

    return (
       
        <List empty={<Empty type="société" />} {...props} bulkActionButtons={<SocieteBulkActions />} title="Liste des Sociétes" >
            <Datagrid>
                <TextField source="nom" label="Nom" />
                <TextField source="tel" label="Téléphone" />
                <TextField source="adresse1" label="Adresse" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
}

export default SocieteList;