// LoginPage.js
import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ResetPwdForm from './ResetPwdForm';
import ForgotPwdForm from './ForgotPwdForm';
import LoginForm from './LoginForm';
import {MyColors} from './MyTheme';
import SerenysLogo from '../assets/SerenysLogo.svg';

const useStyles = makeStyles({
    formWrap: {
        background: '#ffffff',
        boxShadow: 'lightgrey 0 0 3px 0px',
        borderRadius: 4,
        position: 'absolute',
        padding: '30px 20px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 300,
        width: '100%',
        '& form': {
            display: "flex",
            flexDirection: "column"
        },
        '& h3': {
            fontSize: "18px",
            textAlign: "center",
            marginBottom: 30,
        },
        '& button': {
		    backgroundColor: MyColors.lightgreen,
            marginTop: 30,
            marginBottom: 30,
            '&:hover': {
		        backgroundColor: MyColors.darkgreen,
            }
        },
        '& .MuiInput-underline:after':{
            borderColor: MyColors.lightgreen,
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: MyColors.lightgreen,
        }
    },
    loginLogo: {
        display: "block",
        margin: "0 auto 40px",
    }
});


const MyLoginPage = (props) => {
    const classes = useStyles();

    const queryString = require('query-string');
    let parsed = queryString.parse(props.location.search);
    let form = '';
    const [formType, setFormType] = useState('');

    useEffect(() => {
        if(!formType){
            parsed.type === 'reset' ? setFormType('reset') : setFormType('login');
        }
    });

    if(formType === "login"){
        form = (
            <>
                <LoginForm {...props}></LoginForm>
                <span onClick={(e) => setFormType("forgot")}>Mot de passe oublié?</span>
            </>
        );
    } else if(formType ==='reset') {
        let token = parsed.token;
        form = <ResetPwdForm token={token} {...props}/>
    } else if(formType === 'forgot'){
        form = <ForgotPwdForm {...props}/>
    }
    const footer = formType !== 'login' ? <span onClick={(e) => setFormType("login")}>retour</span> : null;
    return(
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={2}>
                <div className={classes.formWrap}>
                    <img className={classes.loginLogo} src={SerenysLogo} alt="Logo Serenys" title="Logo Serenys"/>
                    {form}
                    {footer} 
                </div>
            </Grid>
        </Grid>
    )
}

export default MyLoginPage;