import React, {useEffect, useState } from 'react';
import { 
    useDataProvider,
    Edit,
    SimpleForm, 
    DateField,
    TextField,
    SelectInput,
    TextInput,
} from 'react-admin';
import {validateEditIntervention} from '../InterventionValidator';

const InterventionEditTech = (props) => {

    const dataProvider = useDataProvider()
    const type = props.options.type;
    const id = props.id;

    const [intervention, setInterv] = useState([]);
    const [intervLoaded, setIntervLoaded] = useState(false);

    useEffect(function fetchDefibs() {
        let isMounted = true;
        dataProvider.getOne('interventions/'+type, {id: id})
		.then((response) => {
            if (isMounted) {
                setInterv(response.data);
                setIntervLoaded(true);
            }
		});
        return () => { isMounted = false };

    }, [intervLoaded, dataProvider, id, type]);

    return(
        <Edit {...props}>
            <SimpleForm validate={validateEditIntervention}>
                <TextField source="defibrillateur.site" label="Site"/>
                <DateField source="created" label="Ajouté le"/>
                <TextInput source="description" label="Commentaire demandeur" disabled/>
                <TextInput source="comment_serenys" label="Commentaire Serenys" disabled/>
                <TextInput source="comment_technicien" label="Commentaire Technicien" multiline />
                <SelectInput key={`ionio`} source="statut" label="Statut de l'intervention" choices={[
                    {id: 'processing', name : 'En cours'},
                    intervention.rapport ? {id: 'processed', name : 'Traité', 'selected' : true} : null,
                ]}/>
                <SelectInput key={`niania`} source="attribution" label="Assigné à" choices={[{id: 0, name: 'Serenys'}]}/>
            </SimpleForm>
        </Edit>
    );
}

export default InterventionEditTech;