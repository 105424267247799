import React, { useState } from 'react';
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { makeStyles } from '@material-ui/core/styles';
import Loading from "../../components/Loading";

const useStyles = makeStyles({
	container: {
		height: 500,
		width: '100%',
	},
})

const MapDashboard = (props) => {

	const [activeDefib, setActiveDefib] = useState(null);
	let defibs = props.defibs;
	let isLoaded = props.isLoaded;
	const classes = useStyles();
	

	return(
		<div className={classes.container}>
			<Map center={[48.8534, 2.3488]} zoom={11} className={classes.container}>
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>
				{isLoaded ? (
					<>
					{defibs.map(defib => (
						defib.latitude != null && defib.longitude != null &&
						<Marker
							key={defib.id}
							position={[defib.latitude, defib.longitude]}
							onclick={() => { setActiveDefib(defib)}} />
					))}
					{activeDefib && (
						<Popup 
							position={[activeDefib.latitude, activeDefib.longitude]}
							onClose={() => { setActiveDefib(null) }} >
							<h2>{activeDefib.adresse}, {activeDefib.cp}, {activeDefib.ville}</h2>
							<p>{activeDefib.marque}/{activeDefib.modele}</p>
							<p>Etat: {activeDefib.etat}</p>
							<a href={`/#/defibrillateurs/${activeDefib.id}/show`}>Consulter</a>
						</Popup>							
					)}
					</>
				) : <Loading />}
			</Map>		
		</div>
	)
}

export default MapDashboard;