import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom'
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';

import AdminResources from './routes/admin';
import ClientResources from './routes/client';
import SocieteResources from './routes/societe';
import ReferentResources from './routes/referent';
import TechnicienResources from './routes/technicien';

import Dashboard from './containers/dashboard/Dashboard';
import ProfileEdit from './containers/profile/ProfileEdit';
import ProfileShow from './containers/profile/ProfileShow';
import VerificationDefib from './containers/VerificationDefib';

import frenchMessages from './i18n/fr';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { MyTheme } from './components/MyTheme';
import Menu from './components/Menu';
import MyLayout from './components/MyLayout';
import MyLoginPage from './components/MyLoginPage';


const i18nProvider = polyglotI18nProvider((locale) => frenchMessages, 'fr');

const App = () => (
    <Admin
        key="admin"
        dashboard={Dashboard} 
        dataProvider={dataProvider} 
        loginPage={MyLoginPage}
        authProvider={authProvider} 
        i18nProvider={i18nProvider}
        menu={Menu}
        theme={MyTheme}
        customRoutes={[
            <Route exact key="verification-defibrillateur" path="/verification-defibrillateur" component={VerificationDefib} noLayout />,
            <Route key="my-profile-edit" path="/my-profile/edit" component={ProfileEdit} />,
            <Route key="my-profile-show" path="/my-profile" component={ProfileShow} />,
        ]}
        layout={MyLayout}
    > 
        {permissions => [
            /* displaying routes per roles */
            permissions === 'ROLE_ADMIN' ? AdminResources : null,
            permissions === 'ROLE_CLIENT' ? ClientResources : null,
            permissions === 'ROLE_SOCIETE' ? SocieteResources : null,
            permissions === 'ROLE_REFERENT' ?  ReferentResources : null,
            permissions === 'ROLE_TECHNICIEN' ? TechnicienResources : null,            
            <Resource key="profile" name="profile"/>,
        ]} 
    </Admin>
);
export default App;