import React from 'react';
import { Resource } from 'react-admin';

import {Rapport} from '../containers/rapports/index';
import {Interventions, InterventionsClosed} from '../containers/interventions/index';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faToolbox } from '@fortawesome/free-solid-svg-icons'



const TechnicienResources = [
    <Resource 
        options={{menu: 'rapports', type: 'maintenance', expose : true, key : 'rapports_maint', label: 'Maintenance', 
            icon: <FontAwesomeIcon icon={faClipboardList} />
        }} 
        name="rapports/maintenance" 
        {...Rapport}
    />,
    <Resource 
        options={{menu: 'rapports', type: 'installation', expose : true, key : 'rapports_install', label: 'Installation', 
            icon: <FontAwesomeIcon icon={faClipboardList} />
        }} 
        name="rapports/installation" 
        {...Rapport}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_processing', type: 'processing', label: 'À traiter', 
            icon: <FontAwesomeIcon icon={faToolbox}/>
        }} 
        name="interventions/processing" 
        {...Interventions}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_processed', type: 'processed', label: 'Traitées', 
            icon: <FontAwesomeIcon icon={faToolbox}/>
        }} 
        name="interventions/processed" 
        {...InterventionsClosed}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_closed', type: 'closed', label: 'Fermées', 
            icon: <FontAwesomeIcon icon={faToolbox}/>
        }} 
        name="interventions/closed" 
        {...InterventionsClosed}
    />,
];

export default TechnicienResources;