import React from 'react';
import { 
    List, 
    Datagrid, 
    DateField,
    FunctionField,
    TextField, 
    ShowButton, 
    useTranslate
} from 'react-admin';
import globalStyles from '../../styles';

const InterventionList = (props) => {
    const classes = globalStyles();
    const translate = useTranslate();
    return (
        <List {...props} >
            <Datagrid>
                <DateField label="Date" source="created"/>
                <FunctionField 
                    render={record => record.statut ? translate(`resources.interventions.fields.statut.${record.statut}`) : null} 
                    label="Statut" 
                />
                <TextField label="Client" source="defibrillateur.client.nom" />
                <TextField label="Site" source="defibrillateur.site" />
                <TextField label="Marque" source="defibrillateur.marque" />
                <TextField label="Type" source="type"/>
                <TextField label="Assigné à" source="attribution"/>,
                <ShowButton className={classes.listButton} />
            </Datagrid>
        </List>
    );
}

export default InterventionList;