import React, { useState, useEffect } from 'react';
import config from '../config';
import { useDataProvider } from 'react-admin';
import { Button, Card, Container, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, Snackbar, SnackbarContent, Typography } from '@material-ui/core';
import { MyColors } from '../components/MyTheme';
import Loading from "../components/Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles({

	verifCard: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '40px 0 20px 0',
		position: 'relative',
		width: 300,
		margin: 20,
		"& .MuiButtonBase-root" : {
			color: '#ffffff',
		},
		"& .svg-inline--fa" :{
			fontSize: 30,
			marginBottom: 20,
		}
	},
	verifOk: {
		backgroundColor: MyColors.darkgreen,
		color: '#ffffff'
	},
	verifNok: {
		backgroundColor: MyColors.lightgrey,
		"& .fa-times-circle" : {
			color: '#ce1f1f'
		}
	},
	radioGroup: {
		display: 'flex',
		flexDirection: 'row'
	},
	validateBtn : {
		backgroundColor: MyColors.lightgreen,
		"& .MuiButton-label" : {
			color: '#ffffff',
		},
		"&:hover" : {
			backgroundColor: MyColors.darkgreen,
		}
	}
});

const VerificationDefib = ( props ) => {
    const dataProvider = useDataProvider();
	const classes = useStyles();

	const queryString = require('query-string');
	let parsed = queryString.parse(props.location.search);
	let token = parsed.token
	let [etat, changeStatus] = useState('');
	let [flashMessage, setFlashMessage] = useState('');
	let [openSnackbar, setOpenSnackBar] = useState(false);
	let [defib, setDefib] = useState([]);
	let [showForm, setShowForm] = useState(false);
	let [loaded, setLoaded] = useState(false);
	let [message, setMessage] = useState('');

	
	useEffect(function fetchDefibs(){        
        dataProvider.getOne('verification/defibrillateur', {
			token: token
		}).then((response) => {
			console.log(response.data);
			if(response.data.id !== null){
				setDefib(response.data);
				setLoaded(true);
				setShowForm(true);	
			} else {
				setDefib([]);
				setLoaded(true);
				setShowForm(false);	
				setMessage('Aucun défibrillateur n\'a été trouvé');
			}
        });
    }, [loaded, dataProvider]);

	function handleClose(){
		setOpenSnackBar(false);
	}

	function handleSubmit(event){
		event.preventDefault();
		if(etat !== '') {
			setShowForm(false);
			setMessage('Etat mis à jour');
			fetch(config.url.HOST+'/verification/defibrillateur', {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ 
					token: token, 
					etat: etat,
					idDefib: defib.id
				}),            
			}).then(response => {
				if(response.status === 200){
					setOpenSnackBar(true);
					etat === 'ok' ? setFlashMessage('L\'état à été mis à jour') : setFlashMessage('L\'état à été mis à jour et une demande d\'intervention a été créée ');
				} else {
					setOpenSnackBar(true);
					setFlashMessage('Une erreur est survenue');
				}
			}).catch(err => err);
		}
	} 
	
    return (
		<>		
		{loaded ? (
			<Container maxWidth="md">

				 <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleClose}>
		    		<SnackbarContent message={flashMessage}></SnackbarContent>
		    	</Snackbar>
				<h3>Vérification du défibrillateur</h3> 

				{showForm ? (
				<>
					<Typography>Site : {defib.site}</Typography>
					<Typography>Num série : {defib.num_serie}</Typography>

					<form method="post" onSubmit={handleSubmit}>
						<FormLabel component="legend"></FormLabel>
						<FormControl>
							<RadioGroup className={classes.radioGroup}>
								<Card className={`${classes.verifCard} ${classes.verifOk}`}>
									<FontAwesomeIcon icon={faCheckCircle}/>
									<FormControlLabel
										onChange={(e) => changeStatus(etat = e.target.value)}
										value="ok"
										control={<Radio color="primary" />}
										label="Mon défibrillateur fonctionne"
										labelPlacement="left"
										className={classes.radioInput}
									/>
								</Card>				
								<Card className={`${classes.verifCard} ${classes.verifNok}`}>
									<FontAwesomeIcon icon={faTimesCircle}/>				
									<FormControlLabel
										onChange={(e) => changeStatus(etat = e.target.value)}
										value="notok"
										control={<Radio color="primary" />}
										label="Mon défibrillateur a un problème"
										labelPlacement="left"
									/>
								</Card>
							</RadioGroup>
							<Button className={classes.validateBtn} variant="contained" type='submit'>Valider</Button>
						</FormControl>
					</form>
				</>) : <p>{message}</p>}
			</Container>
			) : <Loading/>}
		</>
    );
};

export default VerificationDefib;