// in src/MyLoginPage.js
import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Loading from "./Loading";


const LoginForm = ( props ) => {


    const login = useLogin();
    const notify = useNotify();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const submit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        login({ username, password })
            .catch(function(){
                setIsLoading(false);
                notify('Invalid email or password');
            });
    };

    return (
        <div>
            {!isLoading ? (
                <form onSubmit={submit}>
                    <TextField required name="username" type="username" label="Email" value={username} 
                        onChange={e => setUsername(e.target.value)} 
                    />
                    <TextField required name="password" type="password" label="Mot de passe" value={password} 
                        onChange={e => setPassword(e.target.value)} />

                    <Button variant="contained" color="primary" type='submit'>Valider</Button>
                </form>
            ) : <Loading/> }
            <Notification />
        </div>
    );
};
export default LoginForm;