import React from 'react';
import { 
    EditView,
    EditController,
    SimpleForm, 
    ImageField,
    ImageInput,
    TextInput,
} from 'react-admin';

import {validateClient} from './ClientValidator';

const redirect = (basePath, id, data) => `/clients/${data.id}`;

const ClientEdit = (props) => {
    return(
        <>
        <EditController {...props}>
            {controllerProps => 

                <EditView {...props} {...controllerProps}>
                    <SimpleForm validate={validateClient} redirect={redirect}>
                        <TextInput source="nom" label="Client"/>
                        <TextInput source="adresse1" label="Adresse 1"/>
                        <TextInput source="adresse2" label="Adresse 2"/>
                        <TextInput source="adresse3" label="Complément d'adresse"/>
                        <TextInput source="cp" label="Code Postal"/>
                        <TextInput source="ville" label="Ville"/>
                        <TextInput source="pays" label="Pays"/>
                        <TextInput source="tel"/>
                        {controllerProps.record && controllerProps.record.logo ? 
                            <ImageField label="Logo" source="logo.0" title="Logo" /> 
                        : null}
                        <ImageInput source="newLogo" label="Modifier le logo" accept="image/*"> 
                            <ImageField source="src" title="Logo" />
                        </ImageInput>
                    </SimpleForm>
                </EditView>
            }
        </EditController>
        </>
    );
}

export default ClientEdit;