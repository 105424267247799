import React from 'react';
import { 
    Edit, 
    SimpleForm,
    RadioButtonGroupInput,
    TextInput,
} from 'react-admin';
import {validateUsers} from './UserValidator';

const UserEdit = (props) => {
    const type = props.options.type;

    return (
        <Edit {...props}>
            <SimpleForm validate={validateUsers}>
                <TextInput source="email" />
                <RadioButtonGroupInput source="civilite" choices={[
                    { id: '0', name: 'Monsieur' },
                    { id: '1', name: 'Madame' },
                ]} />
                {type === 'referent' ? (
                    <TextInput source="fonction" label="Fonction"/>
                ) : null }
                <TextInput source="nom" />
                <TextInput source="prenom" label="Prénom"/>
                {type === 'referent' || type === 'client' || type === 'societe'? ([
                    <TextInput source="adresse1" label="Adresse 1"/>,
                    <TextInput source="adresse2" label="Adresse 2"/>,
                    <TextInput source="adresse3" label="Complément d'adresse"/>,
                    <TextInput source="cp" label="Code Postal"/>,
                    <TextInput source="ville" label="Ville"/>,
                    <TextInput source="pays" label="Pays"/>
                ]) : null}
                <TextInput source="tel" label="Téléphone"/>
            </SimpleForm>
        </Edit>
    )
};


export default UserEdit;
