import React from 'react';
import { Resource } from 'react-admin';

import { AddIntervention } from '../containers/interventions/index';
import { Clients } from '../containers/clients/index';
import { Defibrillateurs } from '../containers/defibrillateurs/index';
import { Interventions, InterventionsClosed } from '../containers/interventions/index';
import { RapportAdmin } from '../containers/rapports/index';
import { Societes } from '../containers/societes/index';
import { Users } from '../containers/users/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat, faUserTie, faUserTag, faUserLock, faUserCog, faClipboardList, faToolbox} from '@fortawesome/free-solid-svg-icons'


const AdminResources = [
    <Resource 
        options={{ expose : true, key : 'clients', label: 'Clients', 
            icon: <FontAwesomeIcon icon={faUserTie} /> 
        }} 
        name="clients" 
        {...Clients}
    />,
    <Resource 
        options={{ expose : true, key : 'societes', label: 'Sociétés', 
            icon: <FontAwesomeIcon icon={faUserTie} />
        }} 
        name="societes" 
        {...Societes}
    />,
    <Resource 
        options={{expose : true, key : 'defibrillateurs', label: 'Défibrillateurs', 
            icon: <FontAwesomeIcon icon={faHeartbeat}/>
        }} 
        name="defibrillateurs" 
        {...Defibrillateurs}
    />,
    <Resource 
        options={{menu: 'users', expose : true, key : 'users_admin', label: 'Admins', type: 'admin', 
            icon: <FontAwesomeIcon icon={faUserLock}/>
        }} 
        name="users/admin" 
        {...Users}
    />,
    <Resource 
        options={{menu: 'users', expose : true, key : 'users_client', label: 'Clients', type: 'client', 
            icon: <FontAwesomeIcon icon={faUserTie}/>
        }} 
        name="users/client" 
        {...Users}
    />,
    <Resource
        options={{menu: 'users', expose : true, key : 'users_societe', label: 'Sociétés', type: 'societe', 
            icon: <FontAwesomeIcon icon={faUserTie}/>
        }} 
        name="users/societe" 
        {...Users}
    />,
    <Resource 
        options={{menu: 'users', expose : true, key : 'users_technicien', label: 'Techniciens', type: 'technicien', 
            icon: <FontAwesomeIcon icon={faUserCog}/>
        }} 
        name="users/technicien" 
        {...Users}
    />,
    <Resource 
        options={{menu: 'users', expose : true, key : 'users_referent', label: 'Référents', type: 'referent', 
            icon: <FontAwesomeIcon icon={faUserTag}/>
        }} 
        name="users/referent" 
        {...Users}
    />,
    <Resource 
        options={{menu: 'rapports', type: 'maintenance', expose : true, key : 'admin_rapports_maint', label: 'Maintenance', 
            icon: <FontAwesomeIcon icon={faClipboardList} />
        }} 
        name="rapports/maintenance" 
        {...RapportAdmin}
    />,
    <Resource 
        options={{menu: 'rapports', type: 'installation', expose : true, key : 'admin_rapports_install', label: 'Installation', 
            icon: <FontAwesomeIcon icon={faClipboardList} />
        }} 
        name="rapports/installation" 
        {...RapportAdmin}
    />,
    <Resource
        options={{menu : 'interventions', type: 'new', expose : true, key : 'admin_interventions_new',  label: 'Nouvelles demandes', 
            icon: <FontAwesomeIcon icon={faToolbox}/>
        }} 
        name="interventions/new" 
        {...Interventions}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'admin_interventions_processing', type: 'processing', label: 'En cours', 
            icon: <FontAwesomeIcon icon={faToolbox}/>
        }} 
        name="interventions/processing" 
        {...Interventions}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'admin_interventions_processed', type: 'processed', label: 'Traitées', 
            icon: <FontAwesomeIcon icon={faToolbox}/>
        }} 
        name="interventions/processed" 
        {...Interventions}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'admin_interventions_closed', type: 'closed', label: 'Fermées', 
            icon: <FontAwesomeIcon icon={faToolbox}/>
        }} 
        name="interventions/closed" 
        {...InterventionsClosed}
    />,
    <Resource key="res_interventions_add"
        options={{menu : 'interventions', expose : false, key : 'interventions_add', label: 'Demander une intervention', 
            icon: <FontAwesomeIcon icon={faToolbox} />
        }} 
        name="interventions" 
        {...AddIntervention} 
    />,
]
export default AdminResources;