
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import {MyColors} from './MyTheme';

const useStyles = makeStyles({
    Loading: {
        margin: "0 auto 30px",
        height: 40,
        width: 40,
        '& .MuiCircularProgress-root': {
            color: MyColors.lightgreen
        }
    }
})

const Loading = () => {
    const classes = useStyles();
    return (
    <div className={classes.Loading}>
        <CircularProgress />
    </div>
    )
};

export default Loading;