import React from 'react';
import { 
    EditButton,
    FunctionField,
    Show, 
    SimpleShowLayout, 
    TextField, 
    TopToolbar,
    usePermissions
} from 'react-admin';
import globalStyles from '../../styles';

const PostShowActions = ({ basePath, data, resource }) => (    
    <TopToolbar>
        <EditButton basePath={`${basePath}/edit`} record="" />
    </TopToolbar>
)


const ProfileShow = ({ staticContext, ...props }) => {
    const classes = globalStyles();

    const { permissions } = usePermissions();
    let extendedFormPerms = ['ROLE_CLIENT', 'ROLE_REFERENT', 'ROLE_SOCIETE'];
    let showExtForm = extendedFormPerms.includes(permissions) ? true : false;

    return (
        <Show
            id="my-profile"
            resource="profile"
            basePath="/my-profile"
            actions={<PostShowActions />}
            className={classes.showLayout}
            {...props}
        >    
            <SimpleShowLayout>
                <TextField label="Email" source="profile.email" />
                <FunctionField render={record => record.civilite === "0" ? 'Monsieur' : 'Madame'} label="Civilité" />
                <TextField source="profile.nom" label="Nom"/>
                <TextField source="profile.prenom" label="Prénom"/>
                <TextField source="profile.tel" label="Téléphone"/>
                {permissions === 'ROLE_REFERENT' ? 
                    <TextField source="profile.fonction" label="Fonction"/>
                : null}
                {showExtForm ? [
                    <TextField source="profile.adresse1" label="Adresse 1"/>,
                    <TextField source="profile.adresse2" label="Adresse 2"/>,
                    <TextField source="profile.adresse3" label="Complément d'adresse"/>,
                    <TextField source="profile.cp" label="Code Postal"/>,
                    <TextField source="profile.ville" label="Ville"/>,
                    <TextField source="profile.pays" label="Pays"/>,
                ] : null}
            </SimpleShowLayout>
        </Show>
    )
};

export default ProfileShow;