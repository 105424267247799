import React from 'react';
import { 
    List,
    Datagrid,
    EditButton,
    ShowButton,
    TextField,
    Filter,
    TextInput
} from 'react-admin';
import globalStyles from '../../styles';
import Empty from '../Empty';

const ClientFilter = ({permissions, ...props}) => (
    <Filter {...props}>
        {permissions === 'ROLE_ADMIN' ? <TextInput label="Recherche" source="q" alwaysOn /> : null }
    </Filter>
)

const ClientList = (props) => {
    const classes = globalStyles()
    return (
        <List empty={<Empty type="client" />} {...props} filters={<ClientFilter permissions={props.permissions}/>} title="Liste des Clients">
            <Datagrid>
                <TextField source="nom" label="Client" />
                <TextField source="tel" label="Téléphone"/>
                <EditButton className={classes.listButton}/>
                <ShowButton className={classes.listButton}/>
            </Datagrid>
        </List>
    )
}

export default ClientList;