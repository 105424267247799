import React from 'react';
import { 
    Create,
    CreateButton,
    TabbedForm, 
    FormTab, 
    AutocompleteInput,
    BooleanInput,
    DateInput, 
    TextInput, 
    SelectInput, 
    FormDataConsumer
} from 'react-admin';
import { useQuery, Loading, Error } from 'react-admin';
import {validateCreateDefibrillateur} from './DefibrillateurValidator';
import Alert from '@material-ui/lab/Alert';


const DefibrillateurCreate = (props) => {
    const { data: clients, loading: clientsQueryLoading, error: clientsQueryError } = useQuery({ 
        type: 'getList',
        resource: 'clients',
        payload: {pagination: {page: 1, perPage: 10}, sort: {field: 'id', order: 'ASC'}}
    });
    const { data: referents, loading: referentsQueryLoading, error: referentsQueryError } = useQuery({ 
        type: 'getList',
        resource: 'users/referent',
        payload: {pagination: {page: 1, perPage: 10}, sort: {field: 'id', order: 'ASC'}}
    });
    if (clientsQueryLoading || referentsQueryLoading) return <Loading />;
    if (clientsQueryError || referentsQueryError) return <Error />;
    if (!clients || !referents) return null;

    var choicesClients = [];
    clients.map(client => (
        choicesClients.push({clientId: client.id, name: client.nom, id:"cli_"+client.id})
    ));
    var choicesReferents = [];
    referents.map(referent => (
        choicesReferents.push({refId: referent.id, name: referent.nom, id: "ref_"+referent.id})
    ));


    let errorAlert = [];

    if(!clients.length) {
        errorAlert.push(<Alert severity="warning">Vous devez créer un client avant de pouvoir ajouter un défibrillateur. <CreateButton variant="contained" basePath="/clients" /> </Alert>);
    }
    if (!referents.length) {
        errorAlert.push(<Alert severity="warning">Vous devez créer un référent avant de pouvoir ajouter un défibrillateur. <CreateButton variant="contained" basePath="/users/referent" /> </Alert>);
    }
    return (
    <>
        {!clients.length || !referents.length ? 
            errorAlert
        :
        <Create {...props} save={false}>
            <TabbedForm submitOnEnter={false} validate={validateCreateDefibrillateur}>
                <FormTab label="Client">
                    <AutocompleteInput source="clientId" label="Choisir un client" optionValue="clientId" choices={choicesClients} translateChoice={false}/>
                </FormTab>
                <FormTab label="Localisation">
                    <TextInput source="site" label="Nom du site"/>
                    <TextInput source="adresse" label="Adresse"/>
                    <TextInput source="ville" label="Ville"/>
                    <TextInput source="cp" label="Code Postal"/>
                    <TextInput source="pays" label="Pays"/>
                </FormTab>
                <FormTab label="Référent">
                    <AutocompleteInput source="refId" label="Choisir un référent" optionValue="refId" choices={choicesReferents} translateChoice={false}/>
                </FormTab>
                <FormTab label="Défibrillateur">
                    <TextInput source="marque" label="Marque"/>
                    <TextInput source="modele" label="Modèle"/>
                    <TextInput source="num_serie" label="Numéro de série"/>
                    <TextInput source="electrodes_adultes_lot" label="N° de lot d'éléctrodes adultes"/>
                    <DateInput source="electrodes_adultes_date" label="Date péremption éléctrodes adultes"/>
                    <TextInput source="electrodes_enfants_lot" label="N° de lot d'éléctrodes enfants"/>
                    <DateInput source="electrodes_enfants_date" label="Date péremption éléctrodes enfants"   />
                    <TextInput source="piles_lot" label="N° de lot de piles / batteries"/>
                    <DateInput source="piles_date" label="Date péremption piles / batteries"   />
                    <TextInput source="piles_armoire_lot" label="N° de lot de piles armoire"/>
                    <DateInput source="piles_armoire_date" label="Date péremption piles armoire"   />
                    <SelectInput source="armoire_type" label="Type d'armoire" choices={[
                        {id: 0, name: 'Type 1'},
                        {id: 1, name: 'Type 2'},
                        {id: 2, name: 'Type 3'},
                        {id: 3, name: 'Type 4'},
                    ]}/>
                    <SelectInput source="connexion" label="Connexion" choices={[
                        {id: 0, name: 'Non'},
                        {id: 1, name: 'Oui'},
                    ]}/>
                    <FormDataConsumer>
                        {({formData, ...rest}) => formData.connexion ?
                            <TextInput source="num_serie_connexion" label="N° série module connexion"/>
                        : null}
                    </FormDataConsumer>
                    <DateInput source="installation_date" label="Date installation"   />
                    <DateInput source="maintenance_date" label="Date dernière maintenance"   />
                    <SelectInput source="etat" label="Etat opérationnel" choices={[
                        {id: 0, name: 'Non'},
                        {id: 1, name: 'Oui'},
                    ]}/>
                    <DateInput source="etat_date" label="Date de vérification Etat" />

                    <DateInput source="maintenance_next_date" label="Date limite prochaine maintenance"   />
                    <SelectInput source="alert" label="Alerte" choices={[
                        {id: 0, name: 'Non'},
                        {id: 1, name: 'Oui'},
                    ]}/>
                </FormTab>
                <FormTab label="Formation">
                    <BooleanInput source="formation" label="Formation effectuée" defaultValue={false}/>
                    <DateInput source="formation_date" label="Date dernière formation"   />
                </FormTab>
            </TabbedForm>
        </Create>
        }
    </>
    )
};

export default DefibrillateurCreate;