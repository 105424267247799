// in src/Dashboard.js
import React from 'react';
import { usePermissions } from 'react-admin';
import AdminDashboard from './show/admin';
import ClientRefDashboard from './show/client_ref';
import SocieteDashboard from './show/societe';
import TechDashboard from './show/technicien';
import { makeStyles } from '@material-ui/core/styles';
import { MyColors } from '../../components/MyTheme';


const useStyles = makeStyles({
    title: {
        color: MyColors.lightgreen,
        fontSize: '2.2em',
        marginTop: 0,
        marginBottom: 40
    }
});

const Dashboard = () => {
    const {permissions} = usePermissions();
	const classes = useStyles();

    return  (
        <div>
            <h1 className={classes.title}>Bienvenue au coeur de l'administration Serenys</h1>
            {permissions === 'ROLE_ADMIN' ? <AdminDashboard /> : null}
            {permissions === 'ROLE_CLIENT' ? <ClientRefDashboard /> : null}
            {permissions === 'ROLE_REFERENT' ? <ClientRefDashboard /> : null}
            {permissions === 'ROLE_SOCIETE' ? <SocieteDashboard /> : null}
            {permissions === 'ROLE_TECHNICIEN' ? <TechDashboard /> : null}
        </div>
    )
};

export default Dashboard;