import React, { useState } from 'react';
import { convertFileToBase64 } from '../utils/fileConverter';
import Button from '@material-ui/core/Button';
import InputIcon from '@material-ui/icons/Input';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import config from '../config';

const ImportButton = (props) => {
	const importButton = document.getElementById('text-button-file');
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [flashMessage, setFlashMessage] = useState('');
	// const [redirect, setRedirect] = useState(false);

	const handleClick = () => {
		importButton.click();
	}

	const handleClose = () => {
		setOpenSnackbar(false);
	}

	const handleChange = (e) => {
		if(!window.confirm("Etes vous sûr?")){
			return;
		}
		let csv = e.target.files[0];
		console.log(csv);
		if(csv) {
			let data = new FormData();
			convertFileToBase64(csv).then((csv64) => {
				const token = sessionStorage.getItem('token');
				data.append('file', csv64)
				fetch(config.url.API+'/import/defibrillateurs', {
					method: 'POST',
					body: data,
					headers: {
						'Authorization': `Bearer ${token}`
					}
				}).then(response => {
					if (response.status >= 200 && response.status < 300) {
						setFlashMessage('Import effectué');
					} else {
						setFlashMessage("Problème lors de l'import");
					}
					setOpenSnackbar(true);
					setTimeout(function(){
						props.updateList(true)
					}, 2000)
				}).catch(err => err);
			})
			console.log(csv);
		}
	}

	return (
		<>
			<input
				type="file"
				id="text-button-file"
				style={{ display: 'none' }}
				accept='.csv'
				onChange={handleChange}
			/>
			<Button startIcon={<InputIcon />} color="primary" size="small" onClick={handleClick}>Importer</Button>
			<Snackbar 
				open={openSnackbar} 
				autoHideDuration={5000} 
				onClose={handleClose}
			>
				<SnackbarContent message={flashMessage}></SnackbarContent>
			</Snackbar>
		</>
	)
} 


export default ImportButton; 
