import React from 'react';
import { 
    Show, 
    SimpleShowLayout, 
    FunctionField,
    TextField, 
} from 'react-admin';
import globalStyles from '../../styles';


const UserShow = (props) => {
    const classes = globalStyles();
    const type = props.options.type;

    return (
        <Show className={classes.showLayout} {...props}>
            <SimpleShowLayout>
                {type === 'client' || type === 'referent' ? (
                        <TextField source="client.nom" label="Client"/>
                ) : null }
                <TextField source="email" />
                <FunctionField render={record => record.civilite === "0" ? 'Monsieur' : 'Madame'} label="Civilité" />
                <TextField source="nom" />
                <TextField source="prenom" label="Prénom"/>
                <TextField source="tel" label="Téléphone"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default UserShow;