import React from 'react';
import { 
    Show, 
    SimpleShowLayout,
    DateField, 
    TextField, 
} from 'react-admin';
import globalStyles from '../../styles';


const SocieteShow = (props) => {
    const classes = globalStyles();

    return (
    <>
        <Show className={classes.showLayout} {...props}>
            <SimpleShowLayout>
                <TextField label="Nom de la société" source="nom"/>
                <TextField label="Ville" source="ville"/>
                <TextField label="Adresse" source="adresse1"/>
                <DateField label="Ajouté le" source="created" />
                <DateField label="Modifié le" source="updated"/>
            </SimpleShowLayout>
        </Show>
    </>
)};

export default SocieteShow;