import React from 'react';
import {
    Button,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import { VisibilityOff } from '@material-ui/icons';

const DeactivateSociete = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [updateMany, { loading }] = useUpdateMany(
        'societes/deactivate',
        selectedIds,
        { active: false },
        {
            onSuccess: () => {
                refresh();
                notify('Societe désactivée');
                unselectAll('societes');
            },
            onFailure: error => notify('Erreur: Impossible de désactiver', 'warning'),
        }
    );

    return (
        <Button
            label="Désactiver"
            disabled={loading}
            onClick={updateMany}
        >
            <VisibilityOff />
        </Button>
    );
};

export default DeactivateSociete;