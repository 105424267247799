import React from 'react';
import { 
    Show, 
    SimpleShowLayout,
    DateField, 
    FunctionField,
    TextField, 
    useTranslate,
} from 'react-admin';
import globalStyles from '../../styles';


const InterventionShow = (props) => {
    const classes = globalStyles();
    const translate = useTranslate();
    return (
    <>
        <Show  className={classes.showLayout} {...props}>
            <SimpleShowLayout>
                <FunctionField 
                    render={record => record.statut ? translate(`resources.interventions.fields.statut.${record.statut}`) : null} 
                    label="Statut" 
                />
                <FunctionField 
                    render={record => record.technicien ? `${record.technicien.nom} ${record.technicien.prenom}` : 'Serenys'} 
                    label="Assigné à" 
                />
                <TextField label="Type d'intervention" source="type"/>
                <TextField label="Client" source="defibrillateur.client.nom"/>
                <TextField label="Site" source="defibrillateur.site"/>
                <TextField source="description"/>
                <DateField label="Ajouté le" source="created" />
                <DateField label="Modifié le" source="updated"/>
            </SimpleShowLayout>
        </Show>
    </>
)};

export default InterventionShow;