import React, {useState, useEffect } from 'react';
import { AppBar, useDataProvider } from 'react-admin';
import MyUserMenu from './MyUserMenu';
import SerenysLogo from '../assets/SerenysLogo.svg';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    spacer : {
        flex: '1'
    },
    logo: {
        height: '50px'
    }
});

const MyAppBar = props => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [isLoaded, setIsLoaded] = useState(false);
    const [logo, setLogo] = useState(false);
   
    useEffect(() => {
        const clientId = sessionStorage.clientId;
        if(clientId){
            dataProvider.getOne('clients', {
                id: clientId
            }).then((response) => {
                if(response){
                    response.data && response.data.logo ? setLogo(response.data.logo) : setLogo(null);
                }
                setIsLoaded(true);
            });
        }
    }, [isLoaded, dataProvider]);
        
    return (
        <AppBar {...props} userMenu={<MyUserMenu />}>            
            <span key="spacer1" className={classes.spacer}/>
            <img className={classes.logo} key="logo_serenys" src={SerenysLogo} alt="Logo Serenys" title="Logo Serenys"/>
            <span key="spacer2" className={classes.spacer}/>
            {logo ? ([
                <img className={classes.logo} key="logo_client" src={logo} alt="Logo" title="Logo"/>,
                <span key="spacer3" className={classes.spacer}/>
            ]) : ''}
        </AppBar>
    );
}
export default MyAppBar;