import RapportList from './RapportList';
import RapportCreate from './RapportCreate';
import RapportEdit from './RapportEdit';


export const Rapport = {
    list: RapportList,
    create: RapportCreate
};

export const RapportAdmin = {
    list: RapportList,
    create: RapportCreate,
    edit: RapportEdit
}
