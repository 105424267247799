const permissions = sessionStorage.permissions;
export const validateCreateIntervention = (values) => {
    const input = [
        'defibId', 'description'
    ];
    if(permissions === 'ROLE_ADMIN'){
        input.push('attribution', 'type');
    }
    const errors = {};

    input.forEach((item, index) => {
        if(!(item in values)) {
            errors[item] = ['errors.global.required'];
        }
    });
    
    return errors;
}

export const validateEditIntervention = (values) => {
    const input = [
        'attribution', 'statut'
    ];
    if(permissions === 'ROLE_ADMIN'){
        input.push('statut');
        input.push('type');
    }
    
    const errors = {};

    input.forEach((item, index) => {
        if(!(item in values)) {
            errors[item] = ['errors.global.required'];
        }
    });
    if(values.statut === 'new'){
        errors['statut'] = ['errors.intervention.edit_statut'];
    }
    
    return errors;
}
