import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import {CreateButton, useDataProvider} from 'react-admin';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: 0.5,
            margin: '0 1em',
            color: 'inherit'
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
    }),
    { name: 'RaEmpty' }
);

const Empty = props => {
    const { resource, basePath } = props;
    const dataProvider = useDataProvider()

    const [clients, setClients] = useState([]);
    const [clientsLoaded, setClientsLoaded] = useState(false);

    useEffect(function fetchDefibs() {
        dataProvider.getList('clients', {
            filters: {}, sort: {field: 'id', order: 'ASC'}, pagination: { page: 1, perPage: 1000 }
        }).then((response) => {
            response ? setClients(response.data) : setClients([]);
            setClientsLoaded(true);
        });        
    }, [clientsLoaded, dataProvider]);

    const classes = useStyles(props);

    const srcTitle = resource.split('/');
    const type = srcTitle[1];
    
    const isClient = type === 'client' ? true : false;

    const isSociete = type === 'societe' ? true : false;
    const isReferent = type === 'referent' ? true : false;

    const refBtn =  clientsLoaded && clients.length ? <CreateButton variant="contained" basePath={basePath} /> : <CreateButton variant="contained" basePath="/clients" />;
    
    return (
        <>
            <Alert severity="info">Pas encore d'utilisateur de type "{type}"</Alert>
            <br/>
            {isClient ? (
                <Alert severity="warning">
                    Vous devez créer un client avant de créer un utilisateur
                </Alert>
            ) : null}
            {isReferent && !clients.length ? (
                <Alert severity="warning">
                    Vous devez créer un client avant de créer un utilisateur
                </Alert>
            ): null}

            {isSociete ? (
                <Alert severity="warning">
                    Vous devez créer une société avant de créer un utilisateur
                </Alert>
            ) : null}

            <div className={classes.toolbar}>
                {isClient ? <CreateButton variant="contained" basePath="/clients" /> : null}
                {isReferent ? refBtn : null}
                {isSociete ? <CreateButton variant="contained" basePath="/societes" /> : null}
                {!isClient && !isSociete && !isReferent ? <CreateButton variant="contained" basePath={basePath} /> : null}
            </div>
        </>
    );
};

export default Empty;