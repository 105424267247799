import React from 'react';
import { 
    ShowController,
    ShowView,
    SimpleShowLayout,
    FunctionField,
    ImageField,
    TextField,
} from 'react-admin';
import { useQuery, Loading, Error } from 'react-admin';
import globalStyles from '../../styles';

const ClientShow = (props) => {
    const classes = globalStyles();
    const { data, loading, error } = useQuery({ 
        type: 'getList',
        resource: 'defibrillateurs',
        payload: {pagination: {page: 1, perPage: 10}, sort: {field: 'id', order: 'ASC'}}
    });
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;

    return (
        <ShowController {...props}>
            {controllerProps => 
                <ShowView className={classes.showLayout} {...props} {...controllerProps}>
                    <SimpleShowLayout>
                        <TextField source="nom" label="Client"/>
                        <FunctionField render={record => record.tel ? record.tel : '-'} label="Téléphone"/>
                        <FunctionField render={record => record.adresse1 ? record.adresse1 : '-'} label="Adresse 1"/>
                        <FunctionField render={record => record.adresse2 ? record.adresse2 : '-'} label="Adresse 2"/>
                        <FunctionField render={record => record.adresse3 ? record.adresse3 : '-'} label="Complément d'adresse"/>
                        <FunctionField render={record => record.cp ? record.cp : '-'} label="Code Postal"/>
                        <FunctionField render={record => record.ville ? record.ville : '-'} label="Ville"/>
                        <FunctionField render={record => record.pays ? record.pays : '-'} label="Pays"/>
                        {controllerProps.record && controllerProps.record.logo ? 
                            <ImageField source="logo.0" title="Logo" label="Logo"/>
                        : null }
                    </SimpleShowLayout>
                </ShowView>
            }
        </ShowController>
    )
};

export default ClientShow;