import InterventionClosedList from './InterventionClosedList';
import InterventionList from './InterventionList';
import InterventionCreate from './InterventionCreate';
import InterventionShow from './InterventionShow';
import InterventionEditAdmin from './edit/admin';
import InterventionEditSociete from './edit/societe';
import InterventionEditTech from './edit/technicien';


const permissions = sessionStorage.getItem('permissions');
function getEditViewByRole () {
    let editView = '';
    switch(permissions){
        case 'ROLE_ADMIN': 
            editView = InterventionEditAdmin;
            break;
        case 'ROLE_SOCIETE':
            editView = InterventionEditSociete;
            break;
        case 'ROLE_TECHNICIEN':
            editView = InterventionEditTech;
            break;
        default:
            break;
    }
    return editView;
}

export const AddIntervention = {
    create: InterventionCreate
};

export const Interventions = {
    list: InterventionList,
    edit: getEditViewByRole(),
    show: InterventionShow,
}

export const InterventionsClosed = {
    list: InterventionClosedList,
    show: InterventionShow,
}