import React from 'react';
import { 
    Edit, 
    SimpleForm, 
    TextInput, 
    RadioButtonGroupInput,
    required, 
    usePermissions,
    ShowButton,
    TopToolbar,
} from 'react-admin';
const redirect = (basePath, id, data) => `/my-profile`;

const PostShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ShowButton basePath={basePath} record="" />
    </TopToolbar>
);


const ProfileEdit = ({ staticContext, ...props }) => {
    const { permissions } = usePermissions();
    let extendedFormPerms = ['ROLE_CLIENT', 'ROLE_REFERENT', 'ROLE_SOCIETE'];
    let showExtForm = extendedFormPerms.includes(permissions) ? true : false;

    return (
        <Edit
            id="my-profile"
            resource="profile"
            basePath="/my-profile"
            actions={<PostShowActions />}
            {...props}
        >
            <SimpleForm redirect={redirect}>
                <RadioButtonGroupInput source="profile.civilite" choices={[
                    { id: '0', name: 'Monsieur' },
                    { id: '1', name: 'Madame' },
                ]} validate={required()} label="Civilité"/>
                <TextInput source="profile.email" validate={required()} label="Email"/>
                <TextInput source="profile.nom" validate={required()} label="Nom" />
                <TextInput source="profile.prenom" validate={required()} label="Prénom" />
                {permissions === 'ROLE_REFERENT' ? 
                    <TextInput source="profile.fonction" label="Fonction"/>
                 : null}
                {showExtForm ? [
                    <TextInput source="profile.adresse1" label="Adresse 1"/>,
                    <TextInput source="profile.adresse2" label="Adresse 2"/>,
                    <TextInput source="profile.adresse3" label="Complément d'adresse"/>,
                    <TextInput source="profile.cp" label="Code Postal"/>,
                    <TextInput source="profile.ville" label="Ville"/>,
                    <TextInput source="profile.pays" label="Pays"/>,
                ] : null}
                <TextInput source="profile.tel" validate={required()} label="Téléphone" />
            </SimpleForm>
        </Edit>
    );
};

export default ProfileEdit;