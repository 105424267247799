import React, {useState} from 'react';
import config from '../config';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import Loading from "./Loading";


const ForgetPwdForm = (props) => {

    let [flashMessage, setFlashMessage] = useState('');
    let [openSnackbar, setOpenSnackBar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


	function handleClose(){
		setOpenSnackBar(false);
	}

    function handleSubmit (event) {
        event.preventDefault();
        let email = event.target.email.value;
        setIsLoading(true);

        fetch(config.url.HOST+'/forgot-pwd', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email }),            
        }).then(response => {
            setIsLoading(false);
            if(response.status === 200){
                setOpenSnackBar(true);
                setFlashMessage('Un mail de réinitialisation vous a été envoyé');
            } else {
                setOpenSnackBar(true);
                setFlashMessage('Cet email ne correspond à aucun utilisateur');
            }
        }).catch(err => err);
    }
	return (
        <div>
            {!isLoading ? (
                <>
                    <Typography variant="h3" component="h3">Mot de passe oublié</Typography>
                    <Snackbar 
			        	open={openSnackbar} 
			        	autoHideDuration={5000} 
			        	onClose={handleClose}
			        >
			        <SnackbarContent message={flashMessage}></SnackbarContent>
			        </Snackbar>
                    <form onSubmit={handleSubmit}>
                        <TextField required name="email" label="Email" />
                        <Button variant="contained" color="primary" type='submit'>Valider</Button>
                    </form>
                </>
            ): <Loading/>}
        </div>
	)
}

export default ForgetPwdForm;