// in src/Menu.js
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DashboardMenuItem, MenuItemLink, getResources, Responsive, usePermissions } from 'react-admin';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import SubMenu from './SubMenu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends, faClipboardList, faToolbox, faUserTie } from '@fortawesome/free-solid-svg-icons'


function Menu ({ onMenuClick, logout }) {
    
    const [menuUsers, setMenuUsers] = useState(false);
    const [menuInterventions, setMenuInterventions] = useState(false);
    const [menuRapports, setMenuRapports] = useState(false);

    const { permissions } = usePermissions();
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const clientId = sessionStorage.clientId;
    const societeId = sessionStorage.societeId;

    const handleToggle = (menu) => {
        !menuUsers && menu === 'menuUsers' ? setMenuUsers(true) : setMenuUsers(false);
        !menuInterventions && menu === 'menuInterventions' ? setMenuInterventions(true) : setMenuInterventions(false);
        !menuRapports && menu === 'menuRapports' ? setMenuRapports(true) : setMenuRapports(false);
    };


    return (
        <>
        {' '}
            <DashboardMenuItem key="menuDashboard"onClick={onMenuClick} />
            {permissions === 'ROLE_ADMIN' ? 
                <SubMenu
                    key="menuUser"
                    handleToggle={() => handleToggle('menuUsers')}
                    isOpen={menuUsers}
                    sidebarIsOpen={open}
                    primaryText="Utilisateurs"
                    name="pos.menu.client"
                    icon={<FontAwesomeIcon icon={faUserFriends}/>} 
                >
                    {resources.map(resource => (
                        <div key={`menuUser${resource.name}`}>
                            {resource.options.expose && resource.options.menu === 'users' ? (
                                <MenuItemLink
                                    key={`menuUserItem${resource.name}`}
                                    to={`/${resource.name}`}
                                    primaryText={resource.options && (resource.options.label || resource.name)}
                                    onClick={onMenuClick}
                                    sidebarIsOpen={open}
                                    leftIcon={resource.options.icon}
                                />
                            ) : ''}
                        </div>
                    ))}
                </SubMenu>
            : null }
            <SubMenu
                    key="menuIntervention"
                    handleToggle={() => handleToggle('menuInterventions')}
                    isOpen={menuInterventions}
                    sidebarIsOpen={open}
                    primaryText="Interventions"
                    name="pos.menu.intervention"
                    icon={<FontAwesomeIcon icon={faToolbox} />} 
                >
                {permissions !== 'ROLE_TECHNICIEN' && permissions !== 'ROLE_SOCIETE' ? 
                    <MenuItemLink
                        key="defibrillateur_add"
                        to={`/interventions/create`}
                        primaryText="Demande d'intervention"
                        sidebarIsOpen={open}
                        leftIcon={<AddIcon />}
                    />
                : null }
                    {resources.map(resource => (
                        <div key={`menuInterv${resource.name}`}>
                        {resource.options.expose && resource.options.menu === 'interventions' ? (
                            <MenuItemLink
                                key={`menuIntervItem${resource.name}`}
                                to={`/${resource.name}`}
                                primaryText={resource.options && (resource.options.label || resource.name)}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                leftIcon={resource.options.icon}
                            />
                            ) : ''}
                        </div>
                    ))}

            </SubMenu>

            <SubMenu
                key="menuRapport"
                handleToggle={() => handleToggle('menuRapports')}
                isOpen={menuRapports}
                sidebarIsOpen={open}
                primaryText="Rapports"
                name="pos.menu.rapport"
                icon={<FontAwesomeIcon icon={faClipboardList} />} 
            >
                {resources.map(resource => (
                    <div key={`menuRapport${resource.name}`}>
                        {resource.options.expose && resource.options.menu === 'rapports' ? (
                            <MenuItemLink
                                key={`menuRapportItem${resource.name}`}
                                to={`/${resource.name}`}
                                primaryText={resource.options && (resource.options.label || resource.name)}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                                leftIcon={resource.options.icon}
                            />
                        ) : ''}
                    </div>
                ))}
            </SubMenu>
            {resources.map(resource => (
                <div key={`Menu${resource.name}`}>
                {resource.options.expose && !resource.options.menu ? (
                    <>
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={resource.options && (resource.options.label || resource.name)}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={resource.options.icon}
                    /></>
                    ) : ''}
                </div>
            ))}


            {permissions === 'ROLE_CLIENT' ? 
                <MenuItemLink
                        key={`client-${clientId}`}
                        to={`/clients/${clientId}`}
                        primaryText="Client"
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<FontAwesomeIcon icon={faUserTie} />}
                />
            : null}
            {permissions === 'ROLE_SOCIETE' ? 
                <MenuItemLink
                        key={`societe-${societeId}`}
                        to={`/societes/${societeId}`}
                        primaryText="Ma société"
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        leftIcon={<EmojiTransportationIcon />}
                />
            : null}

            <Responsive
                small={logout}
                medium={null} // Pass null to render nothing on larger devices
            />
        </>
    );
}

export default withRouter(Menu);