import { makeStyles } from '@material-ui/core/styles';
import { MyColors } from './components/MyTheme';

const globalStyles = makeStyles({
    listButton: {
        color: MyColors.grey,
        fontWeight: 'normal',
        textTransform: 'lowercase',
    },
    checked: {
        color: 'green'
    },
    unchecked: {
        color: 'red'
    },
    showLayout : {
        '& .MuiFormControl-root': {
            display: 'flex',
            flexDirection: 'row'
        },
        '& .MuiInputLabel-root': {
            fontSize: '1.1rem',
            fontWeight: 700,
            maxWidth: '250px',
            width: '100%'
        }, 
        '& .MuiTypography-root' : {
            fontSize: '0.8rem',
            padding: 0,
            position: 'relative',
            top: '-12px',
        }
    }
});

export default globalStyles;