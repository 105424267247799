import DefibrillateurList from './DefibrillateurList';
import DefibrillateurCreate from './DefibrillateurCreate';
import DefibrillateurEdit from './DefibrillateurEdit';
import DefibrillateurShow from './DefibrillateurShow';


export const ClientDefibrillateurs = {
    list: DefibrillateurList,
    show: DefibrillateurShow,
} 

export const Defibrillateurs = {
    list: DefibrillateurList,
    create: DefibrillateurCreate,
    edit: DefibrillateurEdit,
    show: DefibrillateurShow,
}