import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export const MyColors = {
    lightgreen : '#81B10F',
    darkgreen : '#1F7D11',
    grey : '#707070',
    lightgrey: '#D3D3D3',
};

const defaultTheme = createMuiTheme();

export const MyTheme = createMuiTheme({
    palette: {
        primary: {
            main: MyColors.lightgreen,
        },
        secondary: {
            main: '#ffffff',
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        RaLogin: {
            main: {
                backgroundImage: 'none !important'
            }
        },
        RaAppBar: {
            toolbar: {
                paddingRight: '0'
            },
            title : {
                color: MyColors.lightgreen
            }, 
            menuButtonIconOpen: {
                fill: MyColors.lightgreen
            }
        },        
        MuiAppBar: {
            root: {
                padding: '10px 0'
            },
            colorSecondary: {
                color: MyColors.lightgreen
            }
        },
        RaLayout: {
            content: {
                flex: 'none',
                top: 40,
                position: "relative"
            },
            appFrame: {
                marginTop: '80px !important'
            }
        },
        RaList: {
            root: {
                width: '85%',
                [defaultTheme.breakpoints.down('md')]: {
                    width: '100%'
                }
            }
        },
        RaShow: {
            root: {
                width: '85%',
                [defaultTheme.breakpoints.down('md')]: {
                    width: '100%'
                }
            }
        },
        RaCreate: {
            root: {
                width: '85%',
                [defaultTheme.breakpoints.down('md')]: {
                    width: '100%'
                }
            }
        },
        RaEdit: {
            root: {
                width: '85%',
                [defaultTheme.breakpoints.down('md')]: {
                    width: '100%'
                }
            }
        },
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'white', // Some CSS
                borderRadius: '0'
            },
            containedPrimary: {
                color: 'white'
            }
        },  
        MuiPaper: {
            root: {
                boxShadow: 'none !important',
                color: MyColors.grey
            }
        },
        MuiFilledInput : {
            root: {
                backgroundColor: '#F6F6F6',
                borderRadius: '0 !important',
            }
        },
        RaMenuItemLink : {
            active: {
                color: MyColors.lightgreen
            }
        },
        RaDatagrid: {
            headerCell:{
                color: 'white',
                backgroundColor: MyColors.lightgreen,
            },
        },
        MuiTypography: {
            h5: {
                color: MyColors.lightgreen,
                fontWeight: 700
            },
            root: {
                color: MyColors.grey,
            }
        },
        MuiTableCell: {
            head: {
                backgroundColor: MyColors.lightgreen,
                color: 'white',
            },
        },
        SubMenu: {
            listItemText: {
                fontSize: '50px'
            }
        },
    },
});