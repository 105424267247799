import React from 'react';
import { Resource } from 'react-admin';

import { ClientDefibrillateurs } from '../containers/defibrillateurs/index';
import { InterventionsClosed, AddIntervention } from '../containers/interventions/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat, faToolbox } from '@fortawesome/free-solid-svg-icons'

const ReferentResources = [
    <Resource 
        options={{expose: true, key : 'defibrillateurs', label: 'Défibrillateurs', icon: <FontAwesomeIcon icon={faHeartbeat} />}} 
        name="defibrillateurs" 
        {...ClientDefibrillateurs}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_new', type: 'new', label: 'En attente', icon: <FontAwesomeIcon icon={faToolbox}/>}} 
        name="interventions/new" 
        {...InterventionsClosed}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_processing', type: 'processing', label: 'En cours', icon: <FontAwesomeIcon icon={faToolbox}/>}} 
        name="interventions/processing" 
        {...InterventionsClosed}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_processed', type: 'processed', label: 'Traitées', icon: <FontAwesomeIcon icon={faToolbox}/>}} 
        name="interventions/processed" 
        {...InterventionsClosed}
    />,
    <Resource
        options={{menu : 'interventions', expose : true, key : 'interventions_closed', type: 'closed', label: 'Fermées', icon: <FontAwesomeIcon icon={faToolbox} />}} 
        name="interventions/closed"
        {...InterventionsClosed}
    />,
        <Resource key="res_interventions_add"
        options={{menu : 'interventions', expose : false, key : 'interventions_add', label: 'Demander une intervention', icon: <FontAwesomeIcon icon={faToolbox} />}} 
        name="interventions" 
        {...AddIntervention} 
    />,
    
            
];
export default ReferentResources;