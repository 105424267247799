import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    EditButton, 
    ShowButton, 
} from 'react-admin';
import globalStyles from '../../styles';
import Empty from './Empty';
const UserList = (props) => {
    const classes = globalStyles();
    const type = props.options.type;
    return (
        <List empty={<Empty />} {...props} title={`Liste des ${type}s`}>
            <Datagrid>
                {type === 'client' || type === 'referent' ? (
                    <TextField source="client.nom" label="Client"/>
                ) : null }
                {type === 'technicien' || type === 'societe' ? (
                    <TextField source="societe.nom" label="Société"/>
                ) : null }
                <TextField source="email" />
                <TextField source="nom" />
                <TextField source="prenom" label="Prénom"/>
                <TextField source="tel" label="Télephone"/>
                <EditButton className={classes.listButton}/>
                <ShowButton className={classes.listButton}/>
            </Datagrid>
        </List>
    );
};

export default UserList;