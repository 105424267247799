import React, { cloneElement, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { 
    List, 
    Datagrid, 
    TextField, 
    CreateButton,
    ExportButton,
    EditButton, 
    ShowButton,
    Filter,
    ReferenceInput,
    SelectInput,
    FunctionField,
    TextInput,
    //usePermissions,
    TopToolbar, 
    sanitizeListRestProps,
} from 'react-admin';
import ImportButton from '../../utils/ImportButton';
import globalStyles from '../../styles';
import Empty from './Empty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const permissions = sessionStorage.getItem('permissions');

const ListActions = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate, // you can hide CreateButton if hasCreate = false
    basePath,
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    updateList,
    ...rest
}) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
            
        })}
        {permissions === 'ROLE_ADMIN' ? 
            <CreateButton basePath={basePath} />
        : null}
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
        {/* Add your custom actions */}
        <ImportButton updateList={updateList}/>
        
    </TopToolbar>
);

const DefibrillateurFilter = ({permissions, ...props}) => {
    return ( 
        
        <Filter {...props}>
            <TextInput label="Code postal" source="_cp" alwaysOn />
            <ReferenceInput label="Client" source="clientId" reference="clients" allowEmpty>
                <SelectInput optionText="nom" />
            </ReferenceInput>
        </Filter>
    )
};


const DefibrillateurList = (props) => {
    const checked = <FontAwesomeIcon icon={faCheckCircle} />
    const unchecked = <FontAwesomeIcon icon={faTimesCircle} />
    const classes = globalStyles();
    const [redirect, setRedirect] = useState(false);
    const updateList = (bool) => {
        setRedirect(bool);
    }
    useEffect(() => {
        setRedirect(false);
    }, [redirect]);
    
    return(        
            redirect ? <> <Redirect to="/defibrillateurs" />
            <List empty={<Empty type="defibrillateur"/>} className={classes.list} {...props}    
            filters={<DefibrillateurFilter permissions={props.permissions} />} 
            actions={<ListActions updateList={updateList}/>} 
            title="Liste des défibrillateurs">                
                <Datagrid rowClick="show">
                    <FunctionField label="Etat" render={record => record.etat == 1 ? (<span className={classes.checked}>{checked}</span>) : (<span className={classes.unchecked}>{unchecked}</span>) } />
                    <TextField label="Client" source="client.nom" />
                    <TextField label="Nom du site" source="site" />
                    <TextField label="Code postal" source="cp" />
                    <TextField label="Marque" source="marque" />
                    <TextField label="Modèle" source="modele" />
                    {props.permissions === "ROLE_ADMIN" ? <EditButton className={classes.listButton} /> : null}
                    <ShowButton className={classes.listButton} />
                </Datagrid>
            </List> </>
            :
            <List empty={<Empty type="defibrillateur"/>} className={classes.list} {...props} 
            filters={<DefibrillateurFilter permissions={props.permissions} />}
            actions={<ListActions updateList={updateList}/>} 
            title="Liste des défibrillateurs">
                <Datagrid rowClick="show">
                    <FunctionField label="Etat" render={record => record.etat == 1 ? (<span className={classes.checked}>{checked}</span>) : (<span className={classes.unchecked}>{unchecked}</span>) } />
                    <TextField label="Client" source="client.nom" />
                    <TextField label="Nom du site" source="site" />
                    <TextField label="Code postal" source="cp" />
                    <TextField label="Marque" source="marque" />
                    <TextField label="Modèle" source="modele" />
                    {props.permissions === "ROLE_ADMIN" ? <EditButton className={classes.listButton} /> : null}
                    <ShowButton className={classes.listButton}/>
                </Datagrid>
            </List>
    )
};

export default DefibrillateurList;